@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@400;600;700&display=swap');

* {
  font-family: 'Kanit', sans-serif;
}

.box {
  position: relative;
  width: 100%;
  height: 30vh;
  margin: 0 0 60px 0;
  display: block;
  background: 0 0
}

.box .lines {
  position: absolute;
  width: calc(100%);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
}

.box .line {
  position: relative;
  left: 0;
  width: 100%;
  margin: 0 0 5px 0;
  border-radius: 5px
}

.box .line:nth-child(2n) {
  height: calc(20% - 5px)
}

.box .line:nth-child(2n+1) {
  height: calc(30% - 5px)
}

.box .s_shimmer {
  background: rgba(0, 0, 0, .08)
}

.box.shimmer {
  overflow: hidden
}

.box.shimmer::after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .2) 30%, rgba(255, 255, 255, .9) 50%, rgba(255, 255, 255, 0));
  animation: gradient 1.6s infinite;
  animation-timing-function: cubic-bezier(.38, .55, .34, .95);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%) rotate(0);
  border-radius: 5px
}

@keyframes gradient {
  100% {
    transform: translateX(100%) rotate(0)
  }
}

.feed-body {
  visibility: hidden;
  overflow: hidden;
  height: 0
}

.loaded .feed-body {
  visibility: visible;
  overflow: visible;
  height: 100%
}

.theme-dark {
  background-color: #1a2236
}

.theme-dark .app-content {
  background-color: #1a2236
}

.theme-dark .app-content:before {
  background-color: rgba(0, 0, 0, .9)
}

.theme-dark .card {
  background-color: #293145 !important
}

.theme-dark .app-footer {
  background-color: #293145 !important;
  border-top: 0
}

.theme-dark .app-footer i {
  color: #fff
}

.theme-dark .app-header .logo svg path {
  fill: #fff
}

.theme-dark .app-header .menu-icon i {
  color: #fff
}

.theme-dark .h1, .theme-dark .h2, .theme-dark .h3, .theme-dark .h4, .theme-dark .h5, .theme-dark .h6, .theme-dark h1, .theme-dark h2, .theme-dark h3, .theme-dark h4, .theme-dark h5, .theme-dark h6 {
  color: #fff !important
}

.bg-facebook {
  background-color: #0d66ff !important
}

.bg-messenger {
  background-color: #55acee !important
}

.bg-twiiter {
  background-color: #3b5999 !important
}

.bg-linkedin {
  background-color: #0077b5 !important
}

.bg-skype {
  background-color: #00aff0 !important
}

.bg-pinterest {
  background-color: #bd081c !important
}

.bg-instagram {
  background-color: #e4405f !important
}

.bg-flicker {
  background-color: #ff0084 !important
}

.bg-whatsup {
  background-color: #25d366 !important
}

.bg-tumblr {
  background-color: #34465d !important
}

.bg-youtube {
  background-color: #cd201f !important
}

.bg-black {
  background-color: #000
}

.text-facebook {
  color: #555ECE !important
}

.text-messenger {
  color: #555ECE !important
}

.text-twiiter {
  color: #3b5999 !important
}

.text-linkedin {
  color: #555ECE !important
}

.text-skype {
  color: #555ECE !important
}

.text-pinterest {
  color: #bd081c !important
}

.text-instagram {
  color: #e4405f !important
}

.text-current {
  color: #555ECE !important
}

.text-red {
  color: red !important
}

.text-ornage {
  color: #ff9500 !important
}

.text-black {
  color: #000 !important
}

.text-cyan {
  color: #00a9a8 !important
}

.text-yellow {
  color: #ffde00 !important
}

.text-grey {
  color: rgb(194, 194, 194) !important;
}

.bg-black-08 {
  background: rgba(0, 0, 0, .8)
}

.bg-current {
  background-color: #555ECE !important
}

.bg-current-shade {
  background-color: var(--theme-color-shade) !important
}

.bg-primary-gradiant {
  background: linear-gradient(135deg, #555ECE, var(--theme-color-shade)) !important
}

.bg-gold-gradiant {
  background: linear-gradient(to right, #f2994a, #f2c94c) !important
}

.bg-red-gradiant {
  background: linear-gradient(to right, #e44d26, #f16529) !important
}

.bg-mini-gradiant {
  background: linear-gradient(to right, #ee0979, #ff6a00) !important
}

.bg-blue-gradiant {
  background: linear-gradient(to right, #0575e6, #021b79) !important
}

.bg-grey {
  background-color: #eee !important
}

.bg-lightblue {
  background-color: #e8f1fa !important
}

.bg-lightblue2 {
  background-color: #f7f9fd !important
}

.bg-lightgreen {
  background-color: #f2faf6 !important
}

.bg-greyblue {
  background-color: #f6f7fc !important
}

.bg-greylight {
  background-color: #f5f5f5 !important
}

.bg-lightgrey {
  background-color: #f9f9f9 !important
}

.bg-lightbrown {
  background-color: #fdfbf4 !important
}

.bg-orange {
  background-color: #ff9500 !important
}

.bg-cyan {
  background-color: #00a9a8 !important
}

.bg-yellow {
  background-color: #ffde00 !important
}

.bg-blur {
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, .3)
}

.bg-after-fluid {
  position: relative
}

.bg-after-fluid:after {
  top: 0;
  content: "";
  border-radius: 10px;
  background: #ffe2ee;
  height: 100%;
  width: 90%;
  margin: 0 auto;
  display: block;
  position: absolute;
  left: 5%
}

.bg-after-fluid .container {
  position: relative;
  z-index: 2
}

.bg-gradiant-bottom:after {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, .01) 60%, rgba(0, 0, 0, .9) 100%);
  width: 100%;
  height: 250px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1
}

.bg-gradiant-top:before {
  content: "";
  background: linear-gradient(0deg, rgba(0, 0, 0, .01) 60%, rgba(0, 0, 0, .6) 100%);
  width: 100%;
  height: 150px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1
}

.bg-lightblue-after {
  position: relative
}

.bg-lightblue-after .container {
  position: relative;
  z-index: 3
}

.bg-lightblue-after:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background-color: #fef6e7 !important
}

.pattern-layer {
  position: relative
}



.pattern-layer:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, .8)
}

.pattern-layer .layer-after {
  position: relative;
  z-index: 3
}


.color-theme-red {
  --theme-color: #f32323;
  --theme-color-rgb: 255, 59, 48;
  --theme-color-shade: #fb9f81;
  --theme-color-tint: #ff6259
}

.color-theme-green {
  --theme-color: #4cd964;
  --theme-color-rgb: 76, 217, 100;
  --theme-color-shade: #2cd048;
  --theme-color-tint: #6ee081
}

.color-theme-blue {
  --theme-color: #555ECE;
  --theme-color-rgb: 33, 150, 243;
  --theme-color-shade: #555ECE;
  --theme-color-tint: #555ECE
}

.color-theme-deepblue {
  --theme-color: #0f36c0;
  --theme-color-rgb: 33, 150, 243;
  --theme-color-shade: #103ace;
  --theme-color-tint: #1E74FD
}

.color-theme-pink {
  --theme-color: #ff2d55;
  --theme-color-rgb: 255, 45, 85;
  --theme-color-shade: #ff0434;
  --theme-color-tint: #ff5676
}

.color-theme-yellow {
  --theme-color: #fbaf03;
  --theme-color-rgb: 255, 204, 0;
  --theme-color-shade: #d6ab00;
  --theme-color-tint: #ffd429
}

.color-theme-orange {
  --theme-color: #ff9500;
  --theme-color-rgb: 255, 149, 0;
  --theme-color-shade: #d67d00;
  --theme-color-tint: #ffa629
}

.color-theme-purple {
  --theme-color: #9c27b0;
  --theme-color-rgb: 156, 39, 176;
  --theme-color-shade: #7e208f;
  --theme-color-tint: #b92fd1
}

.color-theme-deeppurple {
  --theme-color: #673ab7;
  --theme-color-rgb: 103, 58, 183;
  --theme-color-shade: #563098;
  --theme-color-tint: #7c52c8
}

.color-theme-lightblue {
  --theme-color: #346e93;
  --theme-color-rgb: 90, 200, 250;
  --theme-color-shade: #7ac1ed;
  --theme-color-tint: #82d5fb
}

.color-theme-teal {
  --theme-color: #00bfad;
  --theme-color-rgb: 0, 150, 136;
  --theme-color-shade: #00bfad;
  --theme-color-tint: #00bfad
}

.color-theme-lime {
  --theme-color: #cddc39;
  --theme-color-rgb: 205, 220, 57;
  --theme-color-shade: #bac923;
  --theme-color-tint: #d6e25c
}

.color-theme-deeporange {
  --theme-color: #ff6b22;
  --theme-color-rgb: 255, 107, 34;
  --theme-color-shade: #f85200;
  --theme-color-tint: #ff864b
}

.color-theme-gray {
  --theme-color: #8e8e93;
  --theme-color-rgb: 142, 142, 147;
  --theme-color-shade: #79797f;
  --theme-color-tint: #a3a3a7
}

.color-theme-white {
  --theme-color: #ffffff;
  --theme-color-rgb: 255, 255, 255;
  --theme-color-shade: #ebebeb;
  --theme-color-tint: #ffffff
}

.color-theme-black {
  --theme-color: #000000;
  --theme-color-rgb: 0, 0, 0;
  --theme-color-shade: #000000;
  --theme-color-tint: #141414
}

.color-theme-brown {
  --theme-color: #f2ece5;
  --theme-color-rgb: #f6f2ec;
  --theme-color-shade: #a27761;
  --theme-color-tint: #e4d8ca
}

.color-theme-darkgreen {
  --theme-color: #1c891f;
  --theme-color-rgb: 142, 142, 147;
  --theme-color-shade: #79797f;
  --theme-color-tint: #a3a3a7
}

.color-theme-cayan {
  --theme-color: #7ac1ed;
  --theme-color-rgb: 255, 255, 255;
  --theme-color-shade: #6ae2da;
  --theme-color-tint: #6ae2da
}

.color-theme-darkorchid {
  --theme-color: #9932cc;
  --theme-color-rgb: 0, 0, 0;
  --theme-color-shade: #000000;
  --theme-color-tint: #141414
}

.color-theme-black {
  --theme-color: #000;
  --theme-color-rgb: 0, 0, 0;
  --theme-color-shade: #000;
  --theme-color-tint: #000
}

.color-theme-cadetblue {
  --theme-color: #5f9ea0;
  --theme-color-rgb: 0, 0, 0;
  --theme-color-shade: #5f9ea0;
  --theme-color-tint: #5f9ea0
}

.color-theme-deeppink {
  --theme-color: #FFC0CB;
  --theme-color-rgb: 0, 0, 0;
  --theme-color-shade: #FFC0CB;
  --theme-color-tint: #FFC0CB
}

.w10 {
  width: 12px !important
}

.w15 {
  width: 15px !important
}

.w20 {
  width: 18px !important
}

.w25 {
  width: 25px !important
}

.w30 {
  width: 30px !important
}

.w35 {
  width: 35px !important
}

.w40 {
  width: 40px !important
}

.w45 {
  width: 45px !important
}

.w50 {
  width: 50px !important
}

.w60 {
  width: 60px !important
}

.w65 {
  width: 65px !important
}

.w75 {
  width: 75px !important
}

.w90 {
  width: 90px !important
}

.w100 {
  width: 100px !important
}

.w125 {
  width: 125px !important
}

.w140 {
  width: 140px !important
}

.w150 {
  width: 150px !important
}

.w175 {
  width: 175px !important
}

.w200 {
  width: 200px !important
}

.w250 {
  width: 250px !important
}

.w300 {
  width: 300px !important
}

.w350 {
  width: 350px !important
}

.w400 {
  width: 400px !important
}

.w__12 {
  width: 12.1% !important;
  flex: 0 0 30% !important;
  max-width: 30% !important
}

.w__30 {
  width: 30% !important;
  flex: 0 0 30% !important;
  max-width: 30% !important
}

.w__60 {
  width: 60% !important;
  flex: 0 0 60% !important;
  max-width: 60% !important
}

.w__70 {
  width: 70% !important;
  flex: 0 0 70% !important;
  max-width: 70% !important
}

.w__48 {
  width: 49% !important;
  flex: 0 0 49% !important;
  max-width: 49% !important
}

.h5 {
  height: 5px !important
}

.h10 {
  height: 7px !important
}

.h55 {
  height: 55px !important
}

.h75 {
  height: 75px !important
}

.h90 {
  height: 90px !important
}

.h100 {
  height: 100px !important
}

.h125 {
  height: 125px !important
}

.h150 {
  height: 150px !important
}

.h175 {
  height: 175px !important
}

.h200 {
  height: 200px !important
}

.h240 {
  height: 242px !important
}

.h290 {
  height: 290px !important
}

.h300 {
  height: 300px !important
}

.h361 {
  height: 361px !important
}

.h400 {
  height: 400px !important
}

.h450 {
  height: 450px !important
}

.h500 {
  height: 500px !important
}

.h600 {
  height: 600px !important
}

.btn-round-xss {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  display: inline-block;
  line-height: 25px !important;
  text-align: center
}

.btn-round-xss i {
  line-height: 25px !important
}

.btn-round-xs {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  display: inline-block;
  line-height: 25px !important;
  text-align: center
}

.btn-round-xs i {
  line-height: 25px !important
}

.btn-round-sm {
  width: 35px;
  height: 35px;
  border-radius: 35px;
  display: inline-block;
  line-height: 35px !important;
  text-align: center
}

.btn-round-sm i {
  line-height: 35px !important
}

.btn-round-md {
  width: 45px;
  height: 45px;
  border-radius: 45px;
  display: inline-block;
  line-height: 45px !important;
  text-align: center
}

.btn-round-md i {
  line-height: 45px
}

.btn-round-lg {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  display: inline-block;
  line-height: 50px;
  text-align: center
}

.btn-round-lg i {
  line-height: 50px
}

.btn-round-xl {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center
}

.btn-round-xl i {
  line-height: 60px
}

.btn-round-xl img {
  line-height: 60px
}

.btn-round-xxl {
  width: 70px;
  height: 70px;
  border-radius: 70px;
  display: inline-block;
  line-height: 70px;
  text-align: center
}

.btn-round-xxl i {
  line-height: 70px
}

.btn-round-xxxl {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  display: inline-block;
  line-height: 80px;
  text-align: center
}

.btn-round-xxxl i {
  line-height: 80px
}

.btn-round-xxxxl {
  width: 110px;
  height: 110px;
  border-radius: 110px;
  display: inline-block;
  line-height: 110px;
  text-align: center
}

.btn-round-xxxxl i {
  line-height: 110px
}

.z-index-1 {
  z-index: 2;
  position: relative
}

.cursor-pointer {
  cursor: pointer
}

.float-left {
  float: left !important
}

.float-right {
  float: right !important
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.btn-block {
  display: block !important
}

.badge-primary {
  background-color: #1e74fd
}

.badge-secondary {
  background-color: #673bb7
}

.badge-success {
  background-color: #10d876
}

.badge-info {
  background-color: #2754e6
}

.badge-warning {
  background-color: #fe9431
}

.badge-danger {
  background-color: #e50202
}

.badge-light {
  background-color: #f7f7f7
}

.badge-dark {
  background-color: #343a40
}

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem !important
}

.ls-0 {
  letter-spacing: -.4px
}

.ls-1 {
  letter-spacing: .4px
}

.ls-2 {
  letter-spacing: .6px
}

.ls-3 {
  letter-spacing: 1px
}

.lh-1 {
  line-height: 1
}

.lh-2 {
  line-height: 1.2
}

.lh-3 {
  line-height: 1.4
}

.lh-4 {
  line-height: 1.6
}

.lh-5 {
  line-height: 2
}

.lh-16 {
  line-height: 16px !important
}

.lh-20 {
  line-height: 20px !important
}

.lh-24 {
  line-height: 24px !important
}

.lh-26 {
  line-height: 26px
}

.lh-28 {
  line-height: 28px
}

.lh-30 {
  line-height: 30px !important
}

.lh-32 {
  line-height: 32px
}

.lh-34 {
  line-height: 34px
}

.lh-38 {
  line-height: 38px
}

.font-xsssss {
  font-size: 10px !important
}

.font-xssss {
  font-size: 12px !important
}

.font-xsss {
  font-size: 14px !important
}

.font-xss {
  font-size: 16px !important
}

.font-xs {
  font-size: 20px !important
}

.font-sm {
  font-size: 20px !important
}

.font-md {
  font-size: 22px !important
}

.font-lg {
  font-size: 25px !important
}

.font-xl {
  font-size: 28px !important
}

.font-xxl {
  font-size: 32px !important
}

.display1-size {
  font-size: 36px !important
}

.display2-size {
  font-size: 44px !important
}

.display3-size {
  font-size: 50px !important
}

.display4-size {
  font-size: 60px !important
}

.display5-size {
  font-size: 80px !important
}

.shadow-md {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1) !important
}

.shadow-xs {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .03) !important
}

.shadow-xss {
  box-shadow: 0 8px 30px rgba(0, 0, 0, .05) !important
}

.border-size-sm {
  border-width: 1px
}

.border-size-md {
  border-width: 2px !important
}

.border-size-lg {
  border-width: 3px !important
}

.border-light {
  border: 1px #eee solid
}

.border-light-md {
  border: 2px #f1f1f1 solid
}

.border-dark-md {
  border: 2px #999 solid !important
}

.border-right-light {
  border-right: 1px #eee solid
}

.border-bottom-light {
  border-bottom: 1px #eee solid
}

.border-bottom-dark {
  border-bottom: 2px #333 solid
}

.border-bottom-blue {
  border-bottom: 1px #1254a1 solid
}

.border-dashed {
  border: 2px #ddd dashed !important
}

.border-black {
  border: 2px #000 dashed !important
}

.border-top-lg {
  border-top: 3px solid #000
}

.border-top-xs {
  border-top: 1px solid #f1f1f1
}

.bg-image-cover {
  background-size: cover
}

.bg-image-contain {
  background-size: contain
}

.bg-no-repeat {
  background-repeat: no-repeat
}

.bg-image-center {
  background-position: center center
}

.bg-image-topcenter {
  background-position: top 30px center
}

.bg-image-bottomcenter {
  background-position: bottom center
}

.bg-image-right {
  background-position: right -60px top 0
}

.bg-size-contain {
  background-size: contain
}

.bg-bottom-right {
  background-position: bottom right
}

.bg-top-right {
  background-position: top right
}

.bg-top-left {
  background-position: top left
}

.bg-size-half {
  background-size: 40%
}

.bg-size-quater {
  background-size: 70%
}

.bg-top-right-2 {
  background-position: top 50px right 20px
}

.bg-top-100-right {
  background-position: top 50px right 50px
}

.table-responsive {
  overflow-x: inherit !important
}

.bg-pattern:after, .bg-pattern:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  right: 0;
  top: 0;
  background-color: #fff;
  opacity: .3
}

.bg-pattern:before {
  top: 83px;
  border-radius: 0 80px 80px 80px
}

.bg-pattern .bg-pattern-div:after, .bg-pattern .bg-pattern-div:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  right: 83px;
  top: 0;
  background-color: #fff;
  opacity: .3
}

.bg-pattern .bg-pattern-div:after {
  top: 83px;
  border-radius: 80px 80px 80px 0
}

.bottom-0 {
  bottom: 0
}

.top-0 {
  top: 0 !important
}

.top--15 {
  top: -15px !important;
  z-index: 2
}

.top--30 {
  top: -30px !important;
  z-index: 2
}

.top--10 {
  top: -10px !important;
  z-index: 2
}

.top-5 {
  top: 3px !important
}

.top-10 {
  top: 10px !important
}

.left-auto {
  left: auto !important
}

.left-0 {
  left: 0 !important
}

.left-15 {
  left: 15px
}

.left--15 {
  left: -15px
}

.left--50 {
  left: 50%
}

.right-auto {
  right: auto !important
}

.right-0 {
  right: 0 !important
}

.right-15 {
  right: 15px
}

.right-35 {
  right: 35px
}

.right--10 {
  right: -10px !important
}

.bottom-15 {
  bottom: 15px
}

.pt-7 {
  padding-top: 75px
}

.pb-7 {
  padding-bottom: 75px
}

.pt-10 {
  padding-top: 125px
}

.pb-10 {
  padding-bottom: 125px
}

.p-150 {
  padding-top: 150px;
  padding-bottom: 150px
}

.pl-30 {
  padding-left: 30px
}

.pl-35 {
  padding-left: 35px
}

.pl-7 {
  padding-left: 70px !important
}

.pl-10 {
  padding-left: 100px !important
}

.pl-15 {
  padding-left: 150px !important
}

.pr-15 {
  padding-right: 150px !important
}

.pr-10 {
  padding-right: 100px !important
}

.mt--1 {
  margin-top: -.4rem !important
}

.mt--2 {
  margin-top: -2.5rem !important
}

.mt--6 {
  margin-top: -4.2rem !important
}



.round-lg-btn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px
}

.rounded-xxxl {
  border-radius: 10px !important
}

.rounded-xxl {
  border-radius: 15px !important
}

.rounded-xl {
  border-radius: 30px !important
}

.list-style-disc li {
  list-style-type: disc;
  margin-bottom: 5px
}

:root {
  --f7-theme-color: #132977
}

.theme-dark {
  background-color: #132977
}

.theme-dark .middle-sidebar-header, .theme-dark .middle-sidebar-right-content, .theme-dark .nav-header, .theme-dark .navigation {
  background-color: #293145 !important;
  box-shadow: none !important
}

.theme-dark .navigation .nav-content ul li:not(.logo) a span {
  color: #ddd
}

.theme-dark .navigation .nav-top a i {
  background-color: transparent !important
}

.theme-dark .scroll-bar::-webkit-scrollbar, .theme-dark .scroll-bar::-webkit-scrollbar-track {
  background-color: #1a2236 !important
}

.theme-dark .main-content .middle-sidebar-header .header-menu:after, .theme-dark .main-content .middle-sidebar-header .header-menu:before {
  background-color: #fff
}

.theme-dark .main-content .middle-sidebar-header .menu-search-icon i {
  color: #fff !important
}

.theme-dark .main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content .card {
  border: 0 !important
}

.theme-dark .main-content .middle-sidebar-header {
  border-bottom: 0
}

.theme-dark .modal-popup-chat .modal-popup-wrap .modal-popup-footer, .theme-dark .modal-popup-chat .modal-popup-wrap .modal-popup-header {
  border: 0 !important
}

.theme-dark .input-file+.js-labelFile.btn-tertiary, .theme-dark .modal-popup-chat .modal-popup-wrap, .theme-dark .theme-dark-bg {
  background-color: #1a2236 !important
}

.theme-dark .theme-light-bg {
  background-color: #132977 !important
}

.theme-dark .theme-white-bg {
  background-color: #eee !important
}

.theme-dark ul.list-inline .list-inline-item.border-bottom {
  border-bottom: 0 !important
}

.theme-dark .form-control {
  background-color: #333 !important;
  border-color: #555ECE
}

.theme-dark .dropdown-menu-settings:after {
  border-color: transparent transparent #1a2236 transparent
}

.theme-dark .bg-transparent-card {
  background: 0 0 !important
}

.theme-dark .dropdown-menu-settings, .theme-dark .main-content {
  background-color: #1a2236
}

.theme-dark .form-group.bg-white {
  border: 0 !important;
  background-color: #1a2236 !important
}

.theme-dark .main-content .left-sidebar .left-sidebar-content, .theme-dark .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom, .theme-dark .main-content .left-sidebar .left-sidebar-content .left-sidebar-header {
  background-color: #293145 !important
}

.theme-dark .apexcharts-datalabel-value {
  fill: #eee
}

.theme-dark .form-group label, .theme-dark .text-dark {
  color: #eee !important
}

.theme-dark .form-div .h2, .theme-dark .form-div h2, .theme-dark .member-ul li.active a b, .theme-dark .price-wrap .left-price .h2, .theme-dark .price-wrap .left-price h2, .theme-dark .slide-wrapper .h2, .theme-dark .slide-wrapper h2 {
  color: #eee
}

.theme-dark .header-wrapper .search-div {
  border: 1px #555 solid
}

.theme-dark .header-wrapper .nav-menu li a {
  color: #ddd !important
}

.theme-dark .accordion .card .card-body, .theme-dark .accordion .card .card-header, .theme-dark .form-div, .theme-dark .search-wrapper {
  background-color: #293145
}

.theme-dark .header-wrapper .logo {
  color: #b0c4de
}

.theme-dark .card p {
  color: #ddd !important
}

.theme-dark .accordion .card button, .theme-dark .accordion .card button:after, .theme-dark .form-div .sign-up-text, .theme-dark .form-div label p, .theme-dark .form-div p, .theme-dark .price-wrap .middle-price .h6, .theme-dark .price-wrap .middle-price h6 {
  color: #ddd
}

.theme-dark .form-div .sign-up-text a, .theme-dark .form-div label p a {
  color: #fff;
  margin-left: 5px
}

.theme-dark .navbar-toggler {
  color: #fff;
  border-color: #fff
}

.theme-dark .accordion .card button.small-text, .theme-dark .accordion .card button.small-text a {
  color: #fff
}

.theme-dark .card-header {
  border-bottom: 0 solid #000
}

.theme-dark .dark-bg-transparent {
  background-color: transparent !important
}

.theme-dark .nav-tabs li a.active {
  border-bottom: 2px #ddd solid !important;
  color: #fff !important
}

.theme-dark .form-group label.text-dark-color, .theme-dark .text-dark-color {
  color: #111 !important
}

.theme-dark .bor-0 {
  border: 0 !important
}

.theme-dark .chatlist.style2 .item img {
  border: 2px #1a2236 solid
}

.theme-dark .card-body.border-top-xs {
  border-top: 1px solid #555
}

.theme-dark .nav-header .nav-menu, .theme-dark .nav-header .nav-menu:after, .theme-dark .nav-header .nav-menu:before {
  background-color: #fff
}

.theme-dark a.text-dark:hover {
  color: #eee !important
}

.theme-dark .header-wrapper .logo .light {
  display: none
}

.theme-dark .header-wrapper .logo .dark {
  display: inline-block
}

/*!
 * Bootstrap v5.0.0-beta3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-primary: #1E74FD;
  --bs-secondary: #673BB7;
  --bs-success: #10d876;
  --bs-info: #2754e6;
  --bs-warning: #FE9431;
  --bs-danger: #E50202;
  --bs-light: #f7f7f7;
  --bs-dark: #343a40;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))
}

*, ::after, ::before {
  box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
  :root {
    scroll-behavior: smooth
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: .25
}

hr:not([size]) {
  height: 1px
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #111
}

.h1, h1 {
  font-size: calc(1.275rem + .3vw)
}

@media (min-width:1200px) {
  .h1, h1 {
    font-size: 1.5rem
  }
}

.h2, h2 {
  font-size: 1.25rem
}

.h3, h3 {
  font-size: 1.125rem
}

.h4, h4 {
  font-size: 1rem
}

.h5, h5 {
  font-size: .875rem
}

.h6, h6 {
  font-size: .75rem
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

abbr[data-bs-original-title], abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit
}

ol, ul {
  padding-left: 2rem
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0
}

dt {
  font-weight: 700
}

dd {
  margin-bottom: .5rem;
  margin-left: 0
}

blockquote {
  margin: 0 0 1rem
}

b, strong {
  font-weight: bolder
}

.small, small {
  font-size: .875em
}

.mark, mark {
  padding: .2em;
  background-color: #fcf8e3
}

sub, sup {
  position: relative;
  font-size: .75em;
  line-height: 0;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

a {
  color: #1e74fd;
  text-decoration: underline
}

a:hover {
  color: #185dca
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none
}

code, kbd, pre, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr;
  unicode-bidi: bidi-override
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: .875em
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal
}

code {
  font-size: .875em;
  color: #d63384;
  word-wrap: break-word
}

a>code {
  color: inherit
}

kbd {
  padding: .2rem .4rem;
  font-size: .875em;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700
}

figure {
  margin: 0 0 1rem
}

img, svg {
  vertical-align: middle
}

table {
  caption-side: bottom;
  border-collapse: collapse
}

caption {
  padding-top: .5rem;
  padding-bottom: .5rem;
  color: #aeaed5;
  text-align: left
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent
}

tbody, td, tfoot, th, thead, tr {
  border-color: inherit;
  border-style: solid;
  border-width: 0
}

label {
  display: inline-block
}

button {
  border-radius: 0
}

button:focus:not(:focus-visible) {
  outline: 0
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

button, select {
  text-transform: none
}

[role=button] {
  cursor: pointer
}

select {
  word-wrap: normal
}

select:disabled {
  opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
  display: none
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer
}

::-moz-focus-inner {
  padding: 0;
  border-style: none
}

textarea {
  resize: vertical
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: calc(1.275rem + .3vw);
  line-height: inherit
}

@media (min-width:1200px) {
  legend {
    font-size: 1.5rem
  }
}

legend+* {
  clear: left
}

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
  padding: 0
}

::-webkit-inner-spin-button {
  height: auto
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
  padding: 0
}

::file-selector-button {
  font: inherit
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button
}

output {
  display: inline-block
}

iframe {
  border: 0
}

summary {
  display: list-item;
  cursor: pointer
}

progress {
  vertical-align: baseline
}

[hidden] {
  display: none !important
}

.lead {
  font-size: 1.25rem;
  font-weight: 300
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2
}

@media (min-width:1200px) {
  .display-1 {
    font-size: 5rem
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2
}

@media (min-width:1200px) {
  .display-2 {
    font-size: 4.5rem
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2
}

@media (min-width:1200px) {
  .display-3 {
    font-size: 4rem
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2
}

@media (min-width:1200px) {
  .display-4 {
    font-size: 3.5rem
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2
}

@media (min-width:1200px) {
  .display-5 {
    font-size: 3rem
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2
}

@media (min-width:1200px) {
  .display-6 {
    font-size: 2.5rem
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline {
  padding-left: 0;
  list-style: none
}

.list-inline-item {
  display: inline-block
}

.list-inline-item:not(:last-child) {
  margin-right: .5rem
}

.initialism {
  font-size: .875em;
  text-transform: uppercase
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem
}

.blockquote>:last-child {
  margin-bottom: 0
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: .875em;
  color: #6c757d
}

.blockquote-footer::before {
  content: "— "
}

.img-fluid {
  max-width: 100%;
  height: auto
}

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto
}

.figure {
  display: inline-block
}

.figure-img {
  margin-bottom: .5rem;
  line-height: 1
}

.figure-caption {
  font-size: .875em;
  color: #6c757d
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto
}

@media (min-width:576px) {
  .container, .container-sm {
    max-width: 540px
  }
}

@media (min-width:768px) {
  .container, .container-md, .container-sm {
    max-width: 720px
  }
}

@media (min-width:992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px
  }
}

@media (min-width:1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1199px
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x)/ -2);
  margin-left: calc(var(--bs-gutter-x)/ -2)
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x)/ 2);
  padding-left: calc(var(--bs-gutter-x)/ 2);
  margin-top: var(--bs-gutter-y)
}

.col {
  flex: 1 0 0%
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.3333333333%
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.6666666667%
}

.col-auto {
  flex: 0 0 auto;
  width: auto
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%
}

.col-3 {
  flex: 0 0 auto;
  width: 25%
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%
}

.col-6 {
  flex: 0 0 auto;
  width: 50%
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%
}

.col-9 {
  flex: 0 0 auto;
  width: 75%
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%
}

.col-12 {
  flex: 0 0 auto;
  width: 100%
}

.offset-1 {
  margin-left: 8.3333333333%
}

.offset-2 {
  margin-left: 16.6666666667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.3333333333%
}

.offset-5 {
  margin-left: 41.6666666667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.3333333333%
}

.offset-8 {
  margin-left: 66.6666666667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.3333333333%
}

.offset-11 {
  margin-left: 91.6666666667%
}

.g-0, .gx-0 {
  --bs-gutter-x: 0
}

.g-0, .gy-0 {
  --bs-gutter-y: 0
}

.g-1, .gx-1 {
  --bs-gutter-x: 0.25rem
}

.g-1, .gy-1 {
  --bs-gutter-y: 0.25rem
}

.g-2, .gx-2 {
  --bs-gutter-x: 0.5rem
}

.g-2, .gy-2 {
  --bs-gutter-y: 0.5rem
}

.g-3, .gx-3 {
  --bs-gutter-x: 1rem
}

.g-3, .gy-3 {
  --bs-gutter-y: 1rem
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem
}

.g-5, .gx-5 {
  --bs-gutter-x: 3rem
}

.g-5, .gy-5 {
  --bs-gutter-y: 3rem
}

@media (min-width:576px) {
  .col-sm {
    flex: 1 0 0%
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-sm-0 {
    margin-left: 0
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%
  }

  .offset-sm-3 {
    margin-left: 25%
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%
  }

  .offset-sm-6 {
    margin-left: 50%
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%
  }

  .offset-sm-9 {
    margin-left: 75%
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%
  }

  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0
  }

  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0
  }

  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: 1rem
  }

  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: 1rem
  }

  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 3rem
  }

  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:768px) {
  .col-md {
    flex: 1 0 0%
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-md-0 {
    margin-left: 0
  }

  .offset-md-1 {
    margin-left: 8.3333333333%
  }

  .offset-md-2 {
    margin-left: 16.6666666667%
  }

  .offset-md-3 {
    margin-left: 25%
  }

  .offset-md-4 {
    margin-left: 33.3333333333%
  }

  .offset-md-5 {
    margin-left: 41.6666666667%
  }

  .offset-md-6 {
    margin-left: 50%
  }

  .offset-md-7 {
    margin-left: 58.3333333333%
  }

  .offset-md-8 {
    margin-left: 66.6666666667%
  }

  .offset-md-9 {
    margin-left: 75%
  }

  .offset-md-10 {
    margin-left: 83.3333333333%
  }

  .offset-md-11 {
    margin-left: 91.6666666667%
  }

  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0
  }

  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0
  }

  .g-md-1, .gx-md-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-md-1, .gy-md-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-md-2, .gx-md-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-md-2, .gy-md-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-md-3, .gx-md-3 {
    --bs-gutter-x: 1rem
  }

  .g-md-3, .gy-md-3 {
    --bs-gutter-y: 1rem
  }

  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 3rem
  }

  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:992px) {
  .col-lg {
    flex: 1 0 0%
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-lg-0 {
    margin-left: 0
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%
  }

  .offset-lg-3 {
    margin-left: 25%
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%
  }

  .offset-lg-6 {
    margin-left: 50%
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%
  }

  .offset-lg-9 {
    margin-left: 75%
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%
  }

  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0
  }

  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0
  }

  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: 1rem
  }

  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: 1rem
  }

  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 3rem
  }

  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:1200px) {
  .col-xl {
    flex: 1 0 0%
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-xl-0 {
    margin-left: 0
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%
  }

  .offset-xl-3 {
    margin-left: 25%
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%
  }

  .offset-xl-6 {
    margin-left: 50%
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%
  }

  .offset-xl-9 {
    margin-left: 75%
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%
  }

  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0
  }

  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0
  }

  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: 1rem
  }

  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: 1rem
  }

  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 3rem
  }

  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 3rem
  }
}

@media (min-width:1400) {
  .col-xxl {
    flex: 1 0 0%
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%
  }

  .offset-xxl-0 {
    margin-left: 0
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%
  }

  .offset-xxl-3 {
    margin-left: 25%
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%
  }

  .offset-xxl-6 {
    margin-left: 50%
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%
  }

  .offset-xxl-9 {
    margin-left: 75%
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%
  }

  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0
  }

  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0
  }

  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: 0.25rem
  }

  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: 0.25rem
  }

  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: 0.5rem
  }

  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: 0.5rem
  }

  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: 1rem
  }

  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: 1rem
  }

  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem
  }

  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem
  }

  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 3rem
  }

  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 3rem
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #515184;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #515184;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #515184;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #515184;
  vertical-align: top;
  border-color: #e1e1f0
}

.table>:not(caption)>*>* {
  padding: .5rem .5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
  vertical-align: inherit
}

.table>thead {
  vertical-align: bottom
}

.table>:not(:last-child)>:last-child>* {
  border-bottom-color: currentColor
}

.caption-top {
  caption-side: top
}

.table-sm>:not(caption)>*>* {
  padding: .25rem .25rem
}

.table-bordered>:not(caption)>* {
  border-width: 1px 0
}

.table-bordered>:not(caption)>*>* {
  border-width: 0 1px
}

.table-borderless>:not(caption)>*>* {
  border-bottom-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color)
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color)
}

.table-primary {
  --bs-table-bg: #d2e3ff;
  --bs-table-striped-bg: #c8d8f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdcce6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2d2ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bdcce6
}

.table-secondary {
  --bs-table-bg: #e1d8f1;
  --bs-table-striped-bg: #d6cde5;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbc2d9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d0c8df;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbc2d9
}

.table-success {
  --bs-table-bg: #cff7e4;
  --bs-table-striped-bg: #c5ebd9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badecd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe4d3;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badecd
}

.table-info {
  --bs-table-bg: #d4ddfa;
  --bs-table-striped-bg: #c9d2ee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bfc7e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c4cce7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bfc7e1
}

.table-warning {
  --bs-table-bg: #ffead6;
  --bs-table-striped-bg: #f2decb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d3c1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecd8c6;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d3c1
}

.table-danger {
  --bs-table-bg: #facccc;
  --bs-table-striped-bg: #eec2c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e1b8b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e7bdbd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e1b8b8
}

.table-light {
  --bs-table-bg: #f7f7f7;
  --bs-table-striped-bg: #ebebeb;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dedede;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e4e4e4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dedede
}

.table-dark {
  --bs-table-bg: #343a40;
  --bs-table-striped-bg: #3e444a;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #484e53;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #43494e;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #484e53
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

@media (max-width:575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }
}

@media (max-width:767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }
}

@media (max-width:991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }
}

@media (max-width:1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }
}

@media (max-width:1399.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }
}

.form-label {
  margin-bottom: .5rem
}

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5
}

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem
}

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem
}

.form-text {
  margin-top: .25rem;
  font-size: .875em;
  color: #aeaed5
}

.form-control {
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .form-control {
    transition: none
  }
}

.form-control[type=file] {
  overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer
}

.form-control:focus {
  color: #515184;
  background-color: #fff;
  border-color: #8fbafe;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(30, 116, 253, .25)
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1
}

.form-control::file-selector-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  color: #515184;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .form-control::file-selector-button {
    transition: none
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3
}

.form-control::-webkit-file-upload-button {
  padding: .375rem .75rem;
  margin: -.375rem -.75rem;
  margin-inline-end: .75rem;
  color: #515184;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #515184;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0
}

.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem
}

.form-control-sm::file-selector-button {
  padding: .25rem .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem
}

.form-control-sm::-webkit-file-upload-button {
  padding: .25rem .5rem;
  margin: -.25rem -.5rem;
  margin-inline-end: .5rem
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem
}

.form-control-lg::file-selector-button {
  padding: .5rem 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem
}

.form-control-lg::-webkit-file-upload-button {
  padding: .5rem 1rem;
  margin: -.5rem -1rem;
  margin-inline-end: 1rem
}

textarea.form-control {
  min-height: calc(1.5em + .75rem + 2px)
}

textarea.form-control-sm {
  min-height: calc(1.5em + .5rem + 2px)
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px)
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: .375rem
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: .25rem
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: .25rem
}

.form-select {
  display: block;
  width: 100%;
  padding: .375rem 2.25rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  appearance: none
}

.form-select:focus {
  border-color: #8fbafe;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(30, 116, 253, .25)
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: .75rem;
  background-image: none
}

.form-select:disabled {
  background-color: #e9ecef
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #515184
}

.form-select-sm {
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem
}

.form-select-lg {
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: .125rem
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  appearance: none;
  color-adjust: exact
}

.form-check-input[type=checkbox] {
  border-radius: .25em
}

.form-check-input[type=radio] {
  border-radius: 50%
}

.form-check-input:active {
  filter: brightness(90%)
}

.form-check-input:focus {
  border-color: #8fbafe;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(30, 116, 253, .25)
}

.form-check-input:checked {
  background-color: #1e74fd;
  border-color: #1e74fd
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #1e74fd;
  border-color: #1e74fd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: .5
}

.form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
  opacity: .5
}

.form-switch {
  padding-left: 2.5em
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .form-switch .form-check-input {
    transition: none
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238fbafe'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none
}

.btn-check:disabled+.btn, .btn-check[disabled]+.btn {
  pointer-events: none;
  filter: none;
  opacity: .65
}

.form-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none
}

.form-range:focus {
  outline: 0
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(30, 116, 253, .25)
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(30, 116, 253, .25)
}

.form-range::-moz-focus-outer {
  border: 0
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #1e74fd;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none
}

@media (prefers-reduced-motion:reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #bcd5fe
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #1e74fd;
  border: 0;
  border-radius: 1rem;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none
}

@media (prefers-reduced-motion:reduce) {
  .form-range::-moz-range-thumb {
    transition: none
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #bcd5fe
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem
}

.form-range:disabled {
  pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd
}

.form-floating {
  position: relative
}

.form-floating>.form-control, .form-floating>.form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem .75rem
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem .75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity .1s ease-in-out, transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .form-floating>label {
    transition: none
  }
}

.form-floating>.form-control::placeholder {
  color: transparent
}

.form-floating>.form-control:focus, .form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: .625rem
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: .625rem
}

.form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%
}

.input-group>.form-control, .input-group>.form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0
}

.input-group>.form-control:focus, .input-group>.form-select:focus {
  z-index: 3
}

.input-group .btn {
  position: relative;
  z-index: 2
}

.input-group .btn:focus {
  z-index: 3
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem
}

.input-group-lg>.btn, .input-group-lg>.form-control, .input-group-lg>.form-select, .input-group-lg>.input-group-text {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem
}

.input-group-sm>.btn, .input-group-sm>.form-control, .input-group-sm>.form-select, .input-group-sm>.input-group-text {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem
}

.input-group-lg>.form-select, .input-group-sm>.form-select {
  padding-right: 3rem
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3), .input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4), .input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #10d876
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  color: #000;
  background-color: rgba(16, 216, 118, .9);
  border-radius: .25rem
}

.is-valid~.valid-feedback, .is-valid~.valid-tooltip, .was-validated :valid~.valid-feedback, .was-validated :valid~.valid-tooltip {
  display: block
}

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #10d876;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #10d876;
  box-shadow: 0 0 0 0 rgba(16, 216, 118, .25)
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-valid, .was-validated .form-select:valid {
  border-color: #10d876;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2310d876' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right .75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
  border-color: #10d876;
  box-shadow: 0 0 0 0 rgba(16, 216, 118, .25)
}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
  border-color: #10d876
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: #10d876
}

.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 0 rgba(16, 216, 118, .25)
}

.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
  color: #10d876
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: .5em
}

.input-group .form-control.is-valid, .input-group .form-select.is-valid, .was-validated .input-group .form-control:valid, .was-validated .input-group .form-select:valid {
  z-index: 3
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: .875em;
  color: #e50202
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  color: #fff;
  background-color: rgba(229, 2, 2, .9);
  border-radius: .25rem
}

.is-invalid~.invalid-feedback, .is-invalid~.invalid-tooltip, .was-validated :invalid~.invalid-feedback, .was-validated :invalid~.invalid-tooltip {
  display: block
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #e50202;
  padding-right: calc(1.5em + .75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23E50202'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(.375em + .1875rem) center;
  background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #e50202;
  box-shadow: 0 0 0 0 rgba(229, 2, 2, .25)
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + .75rem);
  background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #e50202;
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23E50202'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23E50202' stroke='none'/%3e%3c/svg%3e");
  background-position: right .75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
  border-color: #e50202;
  box-shadow: 0 0 0 0 rgba(229, 2, 2, .25)
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #e50202
}

.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
  background-color: #e50202
}

.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 0 rgba(229, 2, 2, .25)
}

.form-check-input.is-invalid~.form-check-label, .was-validated .form-check-input:invalid~.form-check-label {
  color: #e50202
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: .5em
}

.input-group .form-control.is-invalid, .input-group .form-select.is-invalid, .was-validated .input-group .form-control:invalid, .was-validated .input-group .form-select:invalid {
  z-index: 3
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #515184;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .btn {
    transition: none
  }
}

.btn:hover {
  color: #515184
}

.btn-check:focus+.btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(30, 116, 253, .25)
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: .65
}

.btn-primary {
  color: #000;
  background-color: #1e74fd;
  border-color: #1e74fd
}

.btn-primary:hover {
  color: #000;
  background-color: #4089fd;
  border-color: #3582fd
}

.btn-check:focus+.btn-primary, .btn-primary:focus {
  color: #000;
  background-color: #4089fd;
  border-color: #3582fd;
  box-shadow: 0 0 0 0 rgba(26, 99, 215, .5)
}

.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
  color: #000;
  background-color: #4b90fd;
  border-color: #3582fd
}

.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(26, 99, 215, .5)
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #000;
  background-color: #1e74fd;
  border-color: #1e74fd
}

.btn-secondary {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7
}

.btn-secondary:hover {
  color: #fff;
  background-color: #58329c;
  border-color: #522f92
}

.btn-check:focus+.btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #58329c;
  border-color: #522f92;
  box-shadow: 0 0 0 0 rgba(126, 88, 194, .5)
}

.btn-check:active+.btn-secondary, .btn-check:checked+.btn-secondary, .btn-secondary.active, .btn-secondary:active, .show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #522f92;
  border-color: #4d2c89
}

.btn-check:active+.btn-secondary:focus, .btn-check:checked+.btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(126, 88, 194, .5)
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7
}

.btn-success {
  color: #000;
  background-color: #10d876;
  border-color: #10d876
}

.btn-success:hover {
  color: #000;
  background-color: #34de8b;
  border-color: #28dc84
}

.btn-check:focus+.btn-success, .btn-success:focus {
  color: #000;
  background-color: #34de8b;
  border-color: #28dc84;
  box-shadow: 0 0 0 0 rgba(14, 184, 100, .5)
}

.btn-check:active+.btn-success, .btn-check:checked+.btn-success, .btn-success.active, .btn-success:active, .show>.btn-success.dropdown-toggle {
  color: #000;
  background-color: #40e091;
  border-color: #28dc84
}

.btn-check:active+.btn-success:focus, .btn-check:checked+.btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(14, 184, 100, .5)
}

.btn-success.disabled, .btn-success:disabled {
  color: #000;
  background-color: #10d876;
  border-color: #10d876
}

.btn-info {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6
}

.btn-info:hover {
  color: #fff;
  background-color: #2147c4;
  border-color: #1f43b8
}

.btn-check:focus+.btn-info, .btn-info:focus {
  color: #fff;
  background-color: #2147c4;
  border-color: #1f43b8;
  box-shadow: 0 0 0 0 rgba(71, 110, 234, .5)
}

.btn-check:active+.btn-info, .btn-check:checked+.btn-info, .btn-info.active, .btn-info:active, .show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1f43b8;
  border-color: #1d3fad
}

.btn-check:active+.btn-info:focus, .btn-check:checked+.btn-info:focus, .btn-info.active:focus, .btn-info:active:focus, .show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(71, 110, 234, .5)
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6
}

.btn-warning {
  color: #000;
  background-color: #fe9431;
  border-color: #fe9431
}

.btn-warning:hover {
  color: #000;
  background-color: #fea450;
  border-color: #fe9f46
}

.btn-check:focus+.btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #fea450;
  border-color: #fe9f46;
  box-shadow: 0 0 0 0 rgba(216, 126, 42, .5)
}

.btn-check:active+.btn-warning, .btn-check:checked+.btn-warning, .btn-warning.active, .btn-warning:active, .show>.btn-warning.dropdown-toggle {
  color: #000;
  background-color: #fea95a;
  border-color: #fe9f46
}

.btn-check:active+.btn-warning:focus, .btn-check:checked+.btn-warning:focus, .btn-warning.active:focus, .btn-warning:active:focus, .show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(216, 126, 42, .5)
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #000;
  background-color: #fe9431;
  border-color: #fe9431
}

.btn-danger {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202
}

.btn-danger:hover {
  color: #fff;
  background-color: #c30202;
  border-color: #b70202
}

.btn-check:focus+.btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #c30202;
  border-color: #b70202;
  box-shadow: 0 0 0 0 rgba(233, 40, 40, .5)
}

.btn-check:active+.btn-danger, .btn-check:checked+.btn-danger, .btn-danger.active, .btn-danger:active, .show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b70202;
  border-color: #ac0202
}

.btn-check:active+.btn-danger:focus, .btn-check:checked+.btn-danger:focus, .btn-danger.active:focus, .btn-danger:active:focus, .show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(233, 40, 40, .5)
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202
}

.btn-light {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f7f7f7
}

.btn-light:hover {
  color: #000;
  background-color: #f8f8f8;
  border-color: #f8f8f8
}

.btn-check:focus+.btn-light, .btn-light:focus {
  color: #000;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
  box-shadow: 0 0 0 0 rgba(210, 210, 210, .5)
}

.btn-check:active+.btn-light, .btn-check:checked+.btn-light, .btn-light.active, .btn-light:active, .show>.btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9f9f9;
  border-color: #f8f8f8
}

.btn-check:active+.btn-light:focus, .btn-check:checked+.btn-light:focus, .btn-light.active:focus, .btn-light:active:focus, .show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(210, 210, 210, .5)
}

.btn-light.disabled, .btn-light:disabled {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f7f7f7
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-dark:hover {
  color: #fff;
  background-color: #2c3136;
  border-color: #2a2e33
}

.btn-check:focus+.btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #2c3136;
  border-color: #2a2e33;
  box-shadow: 0 0 0 0 rgba(82, 88, 93, .5)
}

.btn-check:active+.btn-dark, .btn-check:checked+.btn-dark, .btn-dark.active, .btn-dark:active, .show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #2a2e33;
  border-color: #272c30
}

.btn-check:active+.btn-dark:focus, .btn-check:checked+.btn-dark:focus, .btn-dark.active:focus, .btn-dark:active:focus, .show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0 rgba(82, 88, 93, .5)
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-outline-primary {
  color: #1e74fd;
  border-color: #1e74fd
}

.btn-outline-primary:hover {
  color: #000;
  background-color: #1e74fd;
  border-color: #1e74fd
}

.btn-check:focus+.btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0 rgba(30, 116, 253, .5)
}

.btn-check:active+.btn-outline-primary, .btn-check:checked+.btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  color: #000;
  background-color: #1e74fd;
  border-color: #1e74fd
}

.btn-check:active+.btn-outline-primary:focus, .btn-check:checked+.btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0 rgba(30, 116, 253, .5)
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1e74fd;
  background-color: transparent
}

.btn-outline-secondary {
  color: #673bb7;
  border-color: #673bb7
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7
}

.btn-check:focus+.btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0 rgba(103, 59, 183, .5)
}

.btn-check:active+.btn-outline-secondary, .btn-check:checked+.btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active {
  color: #fff;
  background-color: #673bb7;
  border-color: #673bb7
}

.btn-check:active+.btn-outline-secondary:focus, .btn-check:checked+.btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus, .btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 0 rgba(103, 59, 183, .5)
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #673bb7;
  background-color: transparent
}

.btn-outline-success {
  color: #10d876;
  border-color: #10d876
}

.btn-outline-success:hover {
  color: #000;
  background-color: #10d876;
  border-color: #10d876
}

.btn-check:focus+.btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0 rgba(16, 216, 118, .5)
}

.btn-check:active+.btn-outline-success, .btn-check:checked+.btn-outline-success, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show, .btn-outline-success:active {
  color: #000;
  background-color: #10d876;
  border-color: #10d876
}

.btn-check:active+.btn-outline-success:focus, .btn-check:checked+.btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus, .btn-outline-success:active:focus {
  box-shadow: 0 0 0 0 rgba(16, 216, 118, .5)
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #10d876;
  background-color: transparent
}

.btn-outline-info {
  color: #2754e6;
  border-color: #2754e6
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6
}

.btn-check:focus+.btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0 rgba(39, 84, 230, .5)
}

.btn-check:active+.btn-outline-info, .btn-check:checked+.btn-outline-info, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show, .btn-outline-info:active {
  color: #fff;
  background-color: #2754e6;
  border-color: #2754e6
}

.btn-check:active+.btn-outline-info:focus, .btn-check:checked+.btn-outline-info:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus, .btn-outline-info:active:focus {
  box-shadow: 0 0 0 0 rgba(39, 84, 230, .5)
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #2754e6;
  background-color: transparent
}

.btn-outline-warning {
  color: #fe9431;
  border-color: #fe9431
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #fe9431;
  border-color: #fe9431
}

.btn-check:focus+.btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0 rgba(254, 148, 49, .5)
}

.btn-check:active+.btn-outline-warning, .btn-check:checked+.btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:active {
  color: #000;
  background-color: #fe9431;
  border-color: #fe9431
}

.btn-check:active+.btn-outline-warning:focus, .btn-check:checked+.btn-outline-warning:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus, .btn-outline-warning:active:focus {
  box-shadow: 0 0 0 0 rgba(254, 148, 49, .5)
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fe9431;
  background-color: transparent
}

.btn-outline-danger {
  color: #e50202;
  border-color: #e50202
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202
}

.btn-check:focus+.btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0 rgba(229, 2, 2, .5)
}

.btn-check:active+.btn-outline-danger, .btn-check:checked+.btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show, .btn-outline-danger:active {
  color: #fff;
  background-color: #e50202;
  border-color: #e50202
}

.btn-check:active+.btn-outline-danger:focus, .btn-check:checked+.btn-outline-danger:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus, .btn-outline-danger:active:focus {
  box-shadow: 0 0 0 0 rgba(229, 2, 2, .5)
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e50202;
  background-color: transparent
}

.btn-outline-light {
  color: #f7f7f7;
  border-color: #f7f7f7
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f7f7f7
}

.btn-check:focus+.btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0 rgba(247, 247, 247, .5)
}

.btn-check:active+.btn-outline-light, .btn-check:checked+.btn-outline-light, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show, .btn-outline-light:active {
  color: #000;
  background-color: #f7f7f7;
  border-color: #f7f7f7
}

.btn-check:active+.btn-outline-light:focus, .btn-check:checked+.btn-outline-light:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus, .btn-outline-light:active:focus {
  box-shadow: 0 0 0 0 rgba(247, 247, 247, .5)
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f7f7f7;
  background-color: transparent
}

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-check:focus+.btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, .5)
}

.btn-check:active+.btn-outline-dark, .btn-check:checked+.btn-outline-dark, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show, .btn-outline-dark:active {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40
}

.btn-check:active+.btn-outline-dark:focus, .btn-check:checked+.btn-outline-dark:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus, .btn-outline-dark:active:focus {
  box-shadow: 0 0 0 0 rgba(52, 58, 64, .5)
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent
}

.btn-link {
  font-weight: 400;
  color: #1e74fd;
  text-decoration: underline
}

.btn-link:hover {
  color: #185dca
}

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d
}

.btn-group-lg>.btn, .btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  border-radius: .3rem
}

.btn-group-sm>.btn, .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  border-radius: .2rem
}

.fade {
  transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
  .fade {
    transition: none
  }
}

.fade:not(.show) {
  opacity: 0
}

.collapse:not(.show) {
  display: none
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
  .collapsing {
    transition: none
  }
}

.dropdown, .dropend, .dropstart, .dropup {
  position: relative
}

.dropdown-toggle {
  white-space: nowrap
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
  margin-left: 0
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #515184;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: .25rem
}

.dropdown-menu[data-bs-popper] {
  left: 0;
  margin-top: .125rem
}

.dropdown-menu-start {
  --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0
}

.dropdown-menu-end {
  --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto
}

@media (min-width:576px) {
  .dropdown-menu-sm-start {
    --bs-position: start
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0
  }

  .dropdown-menu-sm-end {
    --bs-position: end
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto
  }
}

@media (min-width:768px) {
  .dropdown-menu-md-start {
    --bs-position: start
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0
  }

  .dropdown-menu-md-end {
    --bs-position: end
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto
  }
}

@media (min-width:992px) {
  .dropdown-menu-lg-start {
    --bs-position: start
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0
  }

  .dropdown-menu-lg-end {
    --bs-position: end
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto
  }
}

@media (min-width:1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0
  }

  .dropdown-menu-xl-end {
    --bs-position: end
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto
  }
}

@media (min-width:1400) {
  .dropdown-menu-xxl-start {
    --bs-position: start
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0
  }

  .dropdown-menu-xxl-end {
    --bs-position: end
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropend .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%
}

.dropend .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-left: .125rem
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropend .dropdown-toggle::after {
  vertical-align: 0
}

.dropstart .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto
}

.dropstart .dropdown-menu[data-bs-popper] {
  margin-top: 0;
  margin-right: .125rem
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""
}

.dropstart .dropdown-toggle::after {
  display: none
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0
}

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, .15)
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #1e2125;
  background-color: #e9ecef
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #1e74fd
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent
}

.dropdown-menu.show {
  display: block
}

.dropdown-header {
  display: block;
  padding: .5rem 1rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap
}

.dropdown-item-text {
  display: block;
  padding: .25rem 1rem;
  color: #212529
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6
}

.dropdown-menu-dark .dropdown-item:focus, .dropdown-menu-dark .dropdown-item:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, .15)
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #1e74fd
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, .15)
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle
}

.btn-group-vertical>.btn, .btn-group>.btn {
  position: relative;
  flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn, .btn-group-vertical>.btn-check:focus+.btn, .btn-group-vertical>.btn.active, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:hover, .btn-group>.btn-check:checked+.btn, .btn-group>.btn-check:focus+.btn, .btn-group>.btn.active, .btn-group>.btn:active, .btn-group>.btn:focus, .btn-group>.btn:hover {
  z-index: 1
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.btn-toolbar .input-group {
  width: auto
}

.btn-group>.btn-group:not(:first-child), .btn-group>.btn:not(:first-child) {
  margin-left: -1px
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:nth-child(n+3), .btn-group>:not(.btn-check)+.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem
}

.dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after {
  margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split, .btn-sm+.dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split, .btn-lg+.dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center
}

.btn-group-vertical>.btn, .btn-group-vertical>.btn-group {
  width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child), .btn-group-vertical>.btn:not(:first-child) {
  margin-top: -1px
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn, .btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn, .btn-group-vertical>.btn~.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .nav-link {
    transition: none
  }
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: .25rem
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #1e74fd
}

.nav-fill .nav-item, .nav-fill>.nav-link {
  flex: 1 1 auto;
  text-align: center
}

.nav-justified .nav-item, .nav-justified>.nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center
}

.nav-fill .nav-item .nav-link, .nav-justified .nav-item .nav-link {
  width: 100%
}

.tab-content>.tab-pane {
  display: none
}

.tab-content>.active {
  display: block
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: .5rem;
  padding-bottom: .5rem
}

.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between
}

.navbar-brand {
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0
}

.navbar-nav .dropdown-menu {
  position: static
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem;
  transition: box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .navbar-toggler {
    transition: none
  }
}

.navbar-toggler:hover {
  text-decoration: none
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto
}

@media (min-width:576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .navbar-expand-sm .navbar-toggler {
    display: none
  }
}

@media (min-width:768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .navbar-expand-md .navbar-toggler {
    display: none
  }
}

@media (min-width:992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .navbar-expand-lg .navbar-toggler {
    display: none
  }
}

@media (min-width:1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .navbar-expand-xl .navbar-toggler {
    display: none
  }
}

@media (min-width:1400) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start
}

.navbar-expand .navbar-nav {
  flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto
}

.navbar-expand .navbar-toggler {
  display: none
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
  color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, .55);
  border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, .55)
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand {
  color: #fff
}

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, .25)
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link {
  color: #fff
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, .55);
  border-color: rgba(255, 255, 255, .1)
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, .55)
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem
}

.card>hr {
  margin-right: 0;
  margin-left: 0
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px)
}

.card>.card-header+.list-group, .card>.list-group+.card-footer {
  border-top: 0
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem
}

.card-title {
  margin-bottom: .5rem
}

.card-subtitle {
  margin-top: -.25rem;
  margin-bottom: 0
}

.card-text:last-child {
  margin-bottom: 0
}

.card-link:hover {
  text-decoration: none
}

.card-link+.card-link {
  margin-left: 1rem
}

.card-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, .03);
  border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-footer {
  padding: .5rem 1rem;
  background-color: rgba(0, 0, 0, .03);
  border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
  margin-right: -.5rem;
  margin-bottom: -.5rem;
  margin-left: -.5rem;
  border-bottom: 0
}

.card-header-pills {
  margin-right: -.5rem;
  margin-left: -.5rem
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(.25rem - 1px)
}

.card-img, .card-img-bottom, .card-img-top {
  width: 100%
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px)
}

.card-group>.card {
  margin-bottom: 15px
}

@media (min-width:576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }

  .card-group>.card:not(:last-child) .card-header, .card-group>.card:not(:last-child) .card-img-top {
    border-top-right-radius: 0
  }

  .card-group>.card:not(:last-child) .card-footer, .card-group>.card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }

  .card-group>.card:not(:first-child) .card-header, .card-group>.card:not(:first-child) .card-img-top {
    border-top-left-radius: 0
  }

  .card-group>.card:not(:first-child) .card-footer, .card-group>.card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #515184;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
  .accordion-button {
    transition: none
  }
}

.accordion-button:not(.collapsed) {
  color: #1b68e4;
  background-color: #e9f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125)
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231b68e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(180deg)
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23515184'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .accordion-button::after {
    transition: none
  }
}

.accordion-button:hover {
  z-index: 2
}

.accordion-button:focus {
  z-index: 3;
  border-color: #8fbafe;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(30, 116, 253, .25)
}

.accordion-header {
  margin-bottom: 0
}

.accordion-item {
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125)
}

.accordion-item:first-of-type {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.accordion-item:last-of-type {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px)
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.accordion-body {
  padding: 1rem 1.25rem
}

.accordion-flush .accordion-collapse {
  border-width: 0
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0
}

.accordion-flush .accordion-item:first-child {
  border-top: 0
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: .5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/")
}

.breadcrumb-item.active {
  color: #6c757d
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none
}

.page-link {
  position: relative;
  display: block;
  color: #1e74fd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .page-link {
    transition: none
  }
}

.page-link:hover {
  z-index: 2;
  color: #185dca;
  background-color: #e9ecef;
  border-color: #dee2e6
}

.page-link:focus {
  z-index: 3;
  color: #185dca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0 rgba(30, 116, 253, .25)
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #1e74fd;
  border-color: #1e74fd
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6
}

.page-link {
  padding: .375rem .75rem
}

.page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem
}

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem
}

.badge {
  display: inline-block;
  padding: .35em .65em;
  font-size: .75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem
}

.badge:empty {
  display: none
}

.btn .badge {
  position: relative;
  top: -1px
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem
}

.alert-heading {
  color: inherit
}

.alert-link {
  font-weight: 700
}

.alert-dismissible {
  padding-right: 3rem
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem
}

.alert-primary {
  color: #124698;
  background-color: #d2e3ff;
  border-color: #bcd5fe
}

.alert-primary .alert-link {
  color: #0e387a
}

.alert-secondary {
  color: #3e236e;
  background-color: #e1d8f1;
  border-color: #d1c4e9
}

.alert-secondary .alert-link {
  color: #321c58
}

.alert-success {
  color: #06562f;
  background-color: #cff7e4;
  border-color: #b7f3d6
}

.alert-success .alert-link {
  color: #054526
}

.alert-info {
  color: #17328a;
  background-color: #d4ddfa;
  border-color: #beccf8
}

.alert-info .alert-link {
  color: #12286e
}

.alert-warning {
  color: #98591d;
  background-color: #ffead6;
  border-color: #ffdfc1
}

.alert-warning .alert-link {
  color: #7a4717
}

.alert-danger {
  color: #890101;
  background-color: #facccc;
  border-color: #f7b3b3
}

.alert-danger .alert-link {
  color: #6e0101
}

.alert-light {
  color: #636363;
  background-color: #fdfdfd;
  border-color: #fdfdfd
}

.alert-light .alert-link {
  color: #4f4f4f
}

.alert-dark {
  color: #1f2326;
  background-color: #d6d8d9;
  border-color: #c2c4c6
}

.alert-dark .alert-link {
  color: #191c1e
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #1e74fd;
  transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
  .progress-bar {
    transition: none
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
  .progress-bar-animated {
    animation: none
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .25rem
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section
}

.list-group-numbered>li::before {
  content: counters(section, ".") ". ";
  counter-increment: section
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit
}

.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa
}

.list-group-item-action:active {
  color: #515184;
  background-color: #e9ecef
}

.list-group-item {
  position: relative;
  display: block;
  padding: .5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #1e74fd;
  border-color: #1e74fd
}

.list-group-item+.list-group-item {
  border-top-width: 0
}

.list-group-item+.list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px
}

.list-group-horizontal {
  flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
  border-bottom-left-radius: .25rem;
  border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
  border-top-right-radius: .25rem;
  border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: 1px;
  border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px
}

@media (min-width:576px) {
  .list-group-horizontal-sm {
    flex-direction: row
  }

  .list-group-horizontal-sm>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-sm>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width:768px) {
  .list-group-horizontal-md {
    flex-direction: row
  }

  .list-group-horizontal-md>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-md>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width:992px) {
  .list-group-horizontal-lg {
    flex-direction: row
  }

  .list-group-horizontal-lg>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-lg>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width:1200px) {
  .list-group-horizontal-xl {
    flex-direction: row
  }

  .list-group-horizontal-xl>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-xl>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width:1400) {
  .list-group-horizontal-xxl {
    flex-direction: row
  }

  .list-group-horizontal-xxl>.list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
  }

  .list-group-horizontal-xxl>.list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

.list-group-flush {
  border-radius: 0
}

.list-group-flush>.list-group-item {
  border-width: 0 0 1px
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0
}

.list-group-item-primary {
  color: #124698;
  background-color: #d2e3ff
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #124698;
  background-color: #bdcce6
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #124698;
  border-color: #124698
}

.list-group-item-secondary {
  color: #3e236e;
  background-color: #e1d8f1
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #3e236e;
  background-color: #cbc2d9
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #3e236e;
  border-color: #3e236e
}

.list-group-item-success {
  color: #06562f;
  background-color: #cff7e4
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #06562f;
  background-color: #badecd
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #06562f;
  border-color: #06562f
}

.list-group-item-info {
  color: #17328a;
  background-color: #d4ddfa
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #17328a;
  background-color: #bfc7e1
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #17328a;
  border-color: #17328a
}

.list-group-item-warning {
  color: #98591d;
  background-color: #ffead6
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #98591d;
  background-color: #e6d3c1
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #98591d;
  border-color: #98591d
}

.list-group-item-danger {
  color: #890101;
  background-color: #facccc
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #890101;
  background-color: #e1b8b8
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #890101;
  border-color: #890101
}

.list-group-item-light {
  color: #636363;
  background-color: #fdfdfd
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #636363;
  background-color: #e4e4e4
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636363;
  border-color: #636363
}

.list-group-item-dark {
  color: #1f2326;
  background-color: #d6d8d9
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1f2326;
  background-color: #c1c2c3
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1f2326;
  border-color: #1f2326
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: .75
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0 rgba(30, 116, 253, .25);
  opacity: 1
}

.btn-close.disabled, .btn-close:disabled {
  pointer-events: none;
  user-select: none;
  opacity: .25
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: .875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  border-radius: .25rem
}

.toast:not(.showing):not(.show) {
  opacity: 0
}

.toast.hide {
  display: none
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none
}

.toast-container>:not(:last-child) {
  margin-bottom: 15px
}

.toast-header {
  display: flex;
  align-items: center;
  padding: .5rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, .85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, .05);
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.toast-header .btn-close {
  margin-right: -.375rem;
  margin-left: .75rem
}

.toast-body {
  padding: .75rem;
  word-wrap: break-word
}

.modal-open {
  overflow: hidden
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translate(0, -50px)
}

@media (prefers-reduced-motion:reduce) {
  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  transform: none
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02)
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem)
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .5
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e1e1f0;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px)
}

.modal-header .btn-close {
  padding: .5rem .5rem;
  margin: -.5rem -.5rem -.5rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: .75rem;
  border-top: 1px solid #e1e1f0;
  border-bottom-right-radius: calc(.3rem - 1px);
  border-bottom-left-radius: calc(.3rem - 1px)
}

.modal-footer>* {
  margin: .25rem
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width:576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem)
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem)
  }

  .modal-sm {
    max-width: 300px
  }
}

@media (min-width:992px) {
  .modal-lg, .modal-xl {
    max-width: 800px
  }
}

@media (min-width:1200px) {
  .modal-xl {
    max-width: 1140px
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0
}

.modal-fullscreen .modal-header {
  border-radius: 0
}

.modal-fullscreen .modal-body {
  overflow-y: auto
}

.modal-fullscreen .modal-footer {
  border-radius: 0
}

@media (max-width:575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
  }

  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto
  }

  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width:767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
  }

  .modal-fullscreen-md-down .modal-header {
    border-radius: 0
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto
  }

  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width:991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
  }

  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto
  }

  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width:1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width:1399.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0
}

.tooltip.show {
  opacity: .9
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top], .bs-tooltip-top {
  padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
  bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .bs-tooltip-top .tooltip-arrow::before {
  top: -1px;
  border-width: .4rem .4rem 0;
  border-top-color: #000
}

.bs-tooltip-auto[data-popper-placement^=right], .bs-tooltip-end {
  padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, .bs-tooltip-end .tooltip-arrow {
  left: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-end .tooltip-arrow::before {
  right: -1px;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000
}

.bs-tooltip-auto[data-popper-placement^=bottom], .bs-tooltip-bottom {
  padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
  top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-bottom .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000
}

.bs-tooltip-auto[data-popper-placement^=left], .bs-tooltip-start {
  padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow {
  right: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before {
  left: -1px;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000
}

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem
}

.popover .popover-arrow::after, .popover .popover-arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow, .bs-popover-top>.popover-arrow {
  bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before, .bs-popover-top>.popover-arrow::before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after, .bs-popover-top>.popover-arrow::after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #fff
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow, .bs-popover-end>.popover-arrow {
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before, .bs-popover-end>.popover-arrow::before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after, .bs-popover-end>.popover-arrow::after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow, .bs-popover-bottom>.popover-arrow {
  top: calc(-.5rem - 1px)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before, .bs-popover-bottom>.popover-arrow::before {
  top: 0;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after, .bs-popover-bottom>.popover-arrow::after {
  top: 1px;
  border-width: 0 .5rem .5rem .5rem;
  border-bottom-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow, .bs-popover-start>.popover-arrow {
  right: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before, .bs-popover-start>.popover-arrow::before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after, .bs-popover-start>.popover-arrow::after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #fff
}

.popover-header {
  padding: .5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #111;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px)
}

.popover-header:empty {
  display: none
}

.popover-body {
  padding: 1rem 1rem;
  color: #515184
}

.carousel {
  position: relative
}

.carousel.pointer-event {
  touch-action: pan-y
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .carousel-item {
    transition: none
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block
}

.active.carousel-item-end, .carousel-item-next:not(.carousel-item-start) {
  transform: translateX(100%)
}

.active.carousel-item-start, .carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(-100%)
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start, .carousel-fade .carousel-item-prev.carousel-item-end, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1
}

.carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {
  .carousel-fade .active.carousel-item-end, .carousel-fade .active.carousel-item-start {
    transition: none
  }
}

.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none
  }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9
}

.carousel-control-prev {
  left: 0
}

.carousel-control-next {
  right: 0
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none
  }
}

.carousel-indicators .active {
  opacity: 1
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center
}

.carousel-dark .carousel-control-next-icon, .carousel-dark .carousel-control-prev-icon {
  filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000
}

.carousel-dark .carousel-caption {
  color: #000
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em
}

@keyframes spinner-grow {
  0% {
    transform: scale(0)
  }

  50% {
    opacity: 1;
    transform: none
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem
}

@media (prefers-reduced-motion:reduce) {
  .spinner-border, .spinner-grow {
    animation-duration: 1.5s
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1040;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform .3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .offcanvas {
    transition: none
  }
}

.offcanvas-header {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem
}

.offcanvas-header .btn-close {
  padding: .5rem .5rem;
  margin: -.5rem -.5rem -.5rem auto
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, .2);
  transform: translateX(-100%)
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, .2);
  transform: translateX(100%)
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, .2);
  transform: translateY(100%)
}

.offcanvas.show {
  transform: none
}

.offcanvas-backdrop::before {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1039;
  width: 100vw;
  height: 100vh;
  content: "";
  background-color: rgba(0, 0, 0, .5)
}

.clearfix::after {
  display: block;
  clear: both;
  content: ""
}

.link-primary {
  color: #1e74fd
}

.link-primary:focus, .link-primary:hover {
  color: #4b90fd
}

.link-secondary {
  color: #673bb7
}

.link-secondary:focus, .link-secondary:hover {
  color: #522f92
}

.link-success {
  color: #10d876
}

.link-success:focus, .link-success:hover {
  color: #40e091
}

.link-info {
  color: #2754e6
}

.link-info:focus, .link-info:hover {
  color: #1f43b8
}

.link-warning {
  color: #fe9431
}

.link-warning:focus, .link-warning:hover {
  color: #fea95a
}

.link-danger {
  color: #e50202
}

.link-danger:focus, .link-danger:hover {
  color: #b70202
}

.link-light {
  color: #f7f7f7
}

.link-light:focus, .link-light:hover {
  color: #f9f9f9
}

.link-dark {
  color: #343a40
}

.link-dark:focus, .link-dark:hover {
  color: #2a2e33
}

.ratio {
  position: relative;
  width: 100%
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: ""
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%)
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%)
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%)
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020
}

@media (min-width:576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width:768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width:992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width:1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width:1400) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.align-baseline {
  vertical-align: baseline !important
}

.align-top {
  vertical-align: top !important
}

.align-middle {
  vertical-align: middle !important
}

.align-bottom {
  vertical-align: bottom !important
}

.align-text-bottom {
  vertical-align: text-bottom !important
}

.align-text-top {
  vertical-align: text-top !important
}

.float-start {
  float: left !important
}

.float-end {
  float: right !important
}

.float-none {
  float: none !important
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.overflow-visible {
  overflow: visible !important
}

.overflow-scroll {
  overflow: scroll !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-grid {
  display: grid !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: flex !important
}

.d-inline-flex {
  display: inline-flex !important
}

.d-none {
  display: none !important
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175) !important
}

.shadow-none {
  box-shadow: none !important
}

.position-static {
  position: static !important
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important
}

.position-fixed {
  position: fixed !important
}

.position-sticky {
  position: sticky !important
}

.top-0 {
  top: 0 !important
}

.top-50 {
  top: 50% !important
}

.top-100 {
  top: 100% !important
}

.bottom-0 {
  bottom: 0 !important
}

.bottom-50 {
  bottom: 50% !important
}

.bottom-100 {
  bottom: 100% !important
}

.start-0 {
  left: 0 !important
}

.start-50 {
  left: 50% !important
}

.start-100 {
  left: 100% !important
}

.end-0 {
  right: 0 !important
}

.end-50 {
  right: 50% !important
}

.end-100 {
  right: 100% !important
}

.translate-middle {
  transform: translate(-50%, -50%) !important
}

.translate-middle-x {
  transform: translateX(-50%) !important
}

.translate-middle-y {
  transform: translateY(-50%) !important
}

.border {
  border: 1px solid #e1e1f0 !important
}

.border-0 {
  border: 0 !important
}

.border-top {
  border-top: 1px solid #e1e1f0 !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-end {
  border-right: 1px solid #e1e1f0 !important
}

.border-end-0 {
  border-right: 0 !important
}

.border-bottom {
  border-bottom: 1px solid #e1e1f0 !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-start {
  border-left: 1px solid #e1e1f0 !important
}

.border-start-0 {
  border-left: 0 !important
}

.border-primary {
  border-color: #1e74fd !important
}

.border-secondary {
  border-color: #673bb7 !important
}

.border-success {
  border-color: #10d876 !important
}

.border-info {
  border-color: #2754e6 !important
}

.border-warning {
  border-color: #fe9431 !important
}

.border-danger {
  border-color: #e50202 !important
}

.border-light {
  border-color: #f7f7f7 !important
}

.border-dark {
  border-color: #343a40 !important
}

.border-white {
  border-color: #fff !important
}

.border-1 {
  border-width: 1px !important
}

.border-2 {
  border-width: 2px !important
}

.border-3 {
  border-width: 3px !important
}

.border-4 {
  border-width: 4px !important
}

.border-5 {
  border-width: 5px !important
}

.w-25 {
  width: 25% !important
}

.w-50 {
  width: 50% !important
}

.w-75 {
  width: 75% !important
}

.w-100 {
  width: 100% !important
}

.w-auto {
  width: auto !important
}

.mw-100 {
  max-width: 100% !important
}

.vw-100 {
  width: 100vw !important
}

.min-vw-100 {
  min-width: 100vw !important
}

.h-25 {
  height: 25% !important
}

.h-50 {
  height: 50% !important
}

.h-75 {
  height: 75% !important
}

.h-100 {
  height: 100% !important
}

.h-auto {
  height: auto !important
}

.mh-100 {
  max-height: 100% !important
}

.vh-100 {
  height: 100vh !important
}

.min-vh-100 {
  min-height: 100vh !important
}

.flex-fill {
  flex: 1 1 auto !important
}

.flex-row {
  flex-direction: row !important
}

.flex-column {
  flex-direction: column !important
}

.flex-row-reverse {
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  flex-direction: column-reverse !important
}

.flex-grow-0 {
  flex-grow: 0 !important
}

.flex-grow-1 {
  flex-grow: 1 !important
}

.flex-shrink-0 {
  flex-shrink: 0 !important
}

.flex-shrink-1 {
  flex-shrink: 1 !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.flex-nowrap {
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important
}

.gap-0 {
  gap: 0 !important
}

.gap-1 {
  gap: .25rem !important
}

.gap-2 {
  gap: .5rem !important
}

.gap-3 {
  gap: 1rem !important
}

.gap-4 {
  gap: 1.5rem !important
}

.gap-5 {
  gap: 3rem !important
}

.justify-content-start {
  justify-content: flex-start !important
}

.justify-content-end {
  justify-content: flex-end !important
}

.justify-content-center {
  justify-content: center !important
}

.justify-content-between {
  justify-content: space-between !important
}

.justify-content-around {
  justify-content: space-around !important
}

.justify-content-evenly {
  justify-content: space-evenly !important
}

.align-items-start {
  align-items: flex-start !important
}

.align-items-end {
  align-items: flex-end !important
}

.align-items-center {
  align-items: center !important
}

.align-items-baseline {
  align-items: baseline !important
}

.align-items-stretch {
  align-items: stretch !important
}

.align-content-start {
  align-content: flex-start !important
}

.align-content-end {
  align-content: flex-end !important
}

.align-content-center {
  align-content: center !important
}

.align-content-between {
  align-content: space-between !important
}

.align-content-around {
  align-content: space-around !important
}

.align-content-stretch {
  align-content: stretch !important
}

.align-self-auto {
  align-self: auto !important
}

.align-self-start {
  align-self: flex-start !important
}

.align-self-end {
  align-self: flex-end !important
}

.align-self-center {
  align-self: center !important
}

.align-self-baseline {
  align-self: baseline !important
}

.align-self-stretch {
  align-self: stretch !important
}

.order-first {
  order: -1 !important
}

.order-0 {
  order: 0 !important
}

.order-1 {
  order: 1 !important
}

.order-2 {
  order: 2 !important
}

.order-3 {
  order: 3 !important
}

.order-4 {
  order: 4 !important
}

.order-5 {
  order: 5 !important
}

.order-last {
  order: 6 !important
}

.m-0 {
  margin: 0 !important
}

.m-1 {
  margin: .25rem !important
}

.m-2 {
  margin: .5rem !important
}

.m-3 {
  margin: 1rem !important
}

.m-4 {
  margin: 1.5rem !important
}

.m-5 {
  margin: 3rem !important
}

.m-auto {
  margin: auto !important
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important
}

.mx-1 {
  margin-right: .25rem !important;
  margin-left: .25rem !important
}

.mx-2 {
  margin-right: .5rem !important;
  margin-left: .5rem !important
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important
}

.my-1 {
  margin-top: .25rem !important;
  margin-bottom: .25rem !important
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important
}

.mt-0 {
  margin-top: 0 !important
}

.mt-1 {
  margin-top: .25rem !important
}

.mt-2 {
  margin-top: .5rem !important
}

.mt-3 {
  margin-top: 1rem !important
}

.mt-4 {
  margin-top: 1.5rem !important
}

.mt-5 {
  margin-top: 3rem !important
}

.mt-auto {
  margin-top: auto !important
}

.me-0 {
  margin-right: 0 !important
}

.me-1 {
  margin-right: .25rem !important
}

.me-2 {
  margin-right: .5rem !important
}

.me-3 {
  margin-right: 1rem !important
}

.me-4 {
  margin-right: 1.5rem !important
}

.me-5 {
  margin-right: 3rem !important
}

.me-auto {
  margin-right: auto !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.mb-1 {
  margin-bottom: .25rem !important
}

.mb-2 {
  margin-bottom: .5rem !important
}

.mb-3 {
  margin-bottom: 1rem !important
}

.mb-4 {
  margin-bottom: 1.5rem !important
}

.mb-5 {
  margin-bottom: 3rem !important
}

.mb-auto {
  margin-bottom: auto !important
}

.ms-0 {
  margin-left: 0 !important
}

.ms-1 {
  margin-left: .25rem !important
}

.ms-2 {
  margin-left: .5rem !important
}

.ms-3 {
  margin-left: 1rem !important
}

.ms-4 {
  margin-left: 1.5rem !important
}

.ms-5 {
  margin-left: 3rem !important
}

.ms-auto {
  margin-left: auto !important
}

.p-0 {
  padding: 0 !important
}

.p-1 {
  padding: .25rem !important
}

.p-2 {
  padding: .5rem !important
}

.p-3 {
  padding: 1rem !important
}

.p-4 {
  padding: 1.5rem !important
}

.p-5 {
  padding: 3rem !important
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important
}

.px-1 {
  padding-right: .25rem !important;
  padding-left: .25rem !important
}

.px-2 {
  padding-right: .5rem !important;
  padding-left: .5rem !important
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.py-1 {
  padding-top: .25rem !important;
  padding-bottom: .25rem !important
}

.py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important
}

.pt-0 {
  padding-top: 0 !important
}

.pt-1 {
  padding-top: .25rem !important
}

.pt-2 {
  padding-top: .5rem !important
}

.pt-3 {
  padding-top: 1rem !important
}

.pt-4 {
  padding-top: 1.5rem !important
}

.pt-5 {
  padding-top: 3rem !important
}

.pe-0 {
  padding-right: 0 !important
}

.pe-1 {
  padding-right: .25rem !important
}

.pe-2 {
  padding-right: .5rem !important
}

.pe-3 {
  padding-right: 1rem !important
}

.pe-4 {
  padding-right: 1.5rem !important
}

.pe-5 {
  padding-right: 3rem !important
}

.pb-0 {
  padding-bottom: 0 !important
}

.pb-1 {
  padding-bottom: .25rem !important
}

.pb-2 {
  padding-bottom: .5rem !important
}

.pb-3 {
  padding-bottom: 1rem !important
}

.pb-4 {
  padding-bottom: 1.5rem !important
}

.pb-5 {
  padding-bottom: 3rem !important
}

.ps-0 {
  padding-left: 0 !important
}

.ps-1 {
  padding-left: .25rem !important
}

.ps-2 {
  padding-left: .5rem !important
}

.ps-3 {
  padding-left: 1rem !important
}

.ps-4 {
  padding-left: 1.5rem !important
}

.ps-5 {
  padding-left: 3rem !important
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important
}

.fs-1 {
  font-size: calc(1.275rem + .3vw) !important
}

.fs-2 {
  font-size: 1.25rem !important
}

.fs-3 {
  font-size: 1.125rem !important
}

.fs-4 {
  font-size: 1rem !important
}

.fs-5 {
  font-size: .875rem !important
}

.fs-6 {
  font-size: .75rem !important
}

.fst-italic {
  font-style: italic !important
}

.fst-normal {
  font-style: normal !important
}

.fw-light {
  font-weight: 300 !important
}

.fw-lighter {
  font-weight: lighter !important
}

.fw-normal {
  font-weight: 400 !important
}

.fw-bold {
  font-weight: 700 !important
}

.fw-bolder {
  font-weight: bolder !important
}

.lh-1 {
  line-height: 1 !important
}

.lh-sm {
  line-height: 1.25 !important
}

.lh-base {
  line-height: 1.5 !important
}

.lh-lg {
  line-height: 2 !important
}

.text-start {
  text-align: left !important
}

.text-end {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

.text-decoration-none {
  text-decoration: none !important
}

.text-decoration-underline {
  text-decoration: underline !important
}

.text-decoration-line-through {
  text-decoration: line-through !important
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.text-wrap {
  white-space: normal !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important
}

.text-primary {
  color: #1e74fd !important
}

.text-secondary {
  color: #673bb7 !important
}

.text-success {
  color: #10d876 !important
}

.text-info {
  color: #2754e6 !important
}

.text-warning {
  color: #fe9431 !important
}

.text-danger {
  color: #e50202 !important
}

.text-light {
  color: #f7f7f7 !important
}

.text-dark {
  color: #343a40 !important
}

.text-white {
  color: #fff !important
}

.text-body {
  color: #515184 !important
}

.text-muted {
  color: #aeaed5 !important
}

.text-black-50 {
  color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
  color: rgba(255, 255, 255, .5) !important
}

.text-reset {
  color: inherit !important
}

.bg-primary {
  background-color: #1e74fd !important
}

.bg-secondary {
  background-color: #673bb7 !important
}

.bg-success {
  background-color: #10d876 !important
}

.bg-info {
  background-color: #2754e6 !important
}

.bg-warning {
  background-color: #fe9431 !important
}

.bg-danger {
  background-color: #e50202 !important
}

.bg-light {
  background-color: #f7f7f7 !important
}

.bg-dark {
  background-color: #343a40 !important
}

.bg-body {
  background-color: #fff !important
}

.bg-white {
  background-color: #fff !important
}

.bg-transparent {
  background-color: transparent !important
}

.bg-gradient {
  background-image: var(--bs-gradient) !important
}

.user-select-all {
  user-select: all !important
}

.user-select-auto {
  user-select: auto !important
}

.user-select-none {
  user-select: none !important
}

.pe-none {
  pointer-events: none !important
}

.pe-auto {
  pointer-events: auto !important
}

.rounded {
  border-radius: .25rem !important
}

.rounded-0 {
  border-radius: 0 !important
}

.rounded-1 {
  border-radius: .2rem !important
}

.rounded-2 {
  border-radius: .25rem !important
}

.rounded-3 {
  border-radius: .3rem !important
}

.rounded-circle {
  border-radius: 50% !important
}

.rounded-pill {
  border-radius: 50rem !important
}

.rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important
}

.rounded-end {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important
}

.rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important
}

.rounded-start {
  border-bottom-left-radius: .25rem !important;
  border-top-left-radius: .25rem !important
}

.visible {
  visibility: visible !important
}

.invisible {
  visibility: hidden !important
}

@media (min-width:576px) {
  .float-sm-start {
    float: left !important
  }

  .float-sm-end {
    float: right !important
  }

  .float-sm-none {
    float: none !important
  }

  .d-sm-inline {
    display: inline !important
  }

  .d-sm-inline-block {
    display: inline-block !important
  }

  .d-sm-block {
    display: block !important
  }

  .d-sm-grid {
    display: grid !important
  }

  .d-sm-table {
    display: table !important
  }

  .d-sm-table-row {
    display: table-row !important
  }

  .d-sm-table-cell {
    display: table-cell !important
  }

  .d-sm-flex {
    display: flex !important
  }

  .d-sm-inline-flex {
    display: inline-flex !important
  }

  .d-sm-none {
    display: none !important
  }

  .flex-sm-fill {
    flex: 1 1 auto !important
  }

  .flex-sm-row {
    flex-direction: row !important
  }

  .flex-sm-column {
    flex-direction: column !important
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .gap-sm-0 {
    gap: 0 !important
  }

  .gap-sm-1 {
    gap: .25rem !important
  }

  .gap-sm-2 {
    gap: .5rem !important
  }

  .gap-sm-3 {
    gap: 1rem !important
  }

  .gap-sm-4 {
    gap: 1.5rem !important
  }

  .gap-sm-5 {
    gap: 3rem !important
  }

  .justify-content-sm-start {
    justify-content: flex-start !important
  }

  .justify-content-sm-end {
    justify-content: flex-end !important
  }

  .justify-content-sm-center {
    justify-content: center !important
  }

  .justify-content-sm-between {
    justify-content: space-between !important
  }

  .justify-content-sm-around {
    justify-content: space-around !important
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important
  }

  .align-items-sm-start {
    align-items: flex-start !important
  }

  .align-items-sm-end {
    align-items: flex-end !important
  }

  .align-items-sm-center {
    align-items: center !important
  }

  .align-items-sm-baseline {
    align-items: baseline !important
  }

  .align-items-sm-stretch {
    align-items: stretch !important
  }

  .align-content-sm-start {
    align-content: flex-start !important
  }

  .align-content-sm-end {
    align-content: flex-end !important
  }

  .align-content-sm-center {
    align-content: center !important
  }

  .align-content-sm-between {
    align-content: space-between !important
  }

  .align-content-sm-around {
    align-content: space-around !important
  }

  .align-content-sm-stretch {
    align-content: stretch !important
  }

  .align-self-sm-auto {
    align-self: auto !important
  }

  .align-self-sm-start {
    align-self: flex-start !important
  }

  .align-self-sm-end {
    align-self: flex-end !important
  }

  .align-self-sm-center {
    align-self: center !important
  }

  .align-self-sm-baseline {
    align-self: baseline !important
  }

  .align-self-sm-stretch {
    align-self: stretch !important
  }

  .order-sm-first {
    order: -1 !important
  }

  .order-sm-0 {
    order: 0 !important
  }

  .order-sm-1 {
    order: 1 !important
  }

  .order-sm-2 {
    order: 2 !important
  }

  .order-sm-3 {
    order: 3 !important
  }

  .order-sm-4 {
    order: 4 !important
  }

  .order-sm-5 {
    order: 5 !important
  }

  .order-sm-last {
    order: 6 !important
  }

  .m-sm-0 {
    margin: 0 !important
  }

  .m-sm-1 {
    margin: .25rem !important
  }

  .m-sm-2 {
    margin: .5rem !important
  }

  .m-sm-3 {
    margin: 1rem !important
  }

  .m-sm-4 {
    margin: 1.5rem !important
  }

  .m-sm-5 {
    margin: 3rem !important
  }

  .m-sm-auto {
    margin: auto !important
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-sm-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-sm-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-sm-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-sm-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-sm-0 {
    margin-top: 0 !important
  }

  .mt-sm-1 {
    margin-top: .25rem !important
  }

  .mt-sm-2 {
    margin-top: .5rem !important
  }

  .mt-sm-3 {
    margin-top: 1rem !important
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important
  }

  .mt-sm-5 {
    margin-top: 3rem !important
  }

  .mt-sm-auto {
    margin-top: auto !important
  }

  .me-sm-0 {
    margin-right: 0 !important
  }

  .me-sm-1 {
    margin-right: .25rem !important
  }

  .me-sm-2 {
    margin-right: .5rem !important
  }

  .me-sm-3 {
    margin-right: 1rem !important
  }

  .me-sm-4 {
    margin-right: 1.5rem !important
  }

  .me-sm-5 {
    margin-right: 3rem !important
  }

  .me-sm-auto {
    margin-right: auto !important
  }

  .mb-sm-0 {
    margin-bottom: 0 !important
  }

  .mb-sm-1 {
    margin-bottom: .25rem !important
  }

  .mb-sm-2 {
    margin-bottom: .5rem !important
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important
  }

  .mb-sm-auto {
    margin-bottom: auto !important
  }

  .ms-sm-0 {
    margin-left: 0 !important
  }

  .ms-sm-1 {
    margin-left: .25rem !important
  }

  .ms-sm-2 {
    margin-left: .5rem !important
  }

  .ms-sm-3 {
    margin-left: 1rem !important
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important
  }

  .ms-sm-5 {
    margin-left: 3rem !important
  }

  .ms-sm-auto {
    margin-left: auto !important
  }

  .p-sm-0 {
    padding: 0 !important
  }

  .p-sm-1 {
    padding: .25rem !important
  }

  .p-sm-2 {
    padding: .5rem !important
  }

  .p-sm-3 {
    padding: 1rem !important
  }

  .p-sm-4 {
    padding: 1.5rem !important
  }

  .p-sm-5 {
    padding: 3rem !important
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-sm-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-sm-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-sm-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-sm-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-sm-0 {
    padding-top: 0 !important
  }

  .pt-sm-1 {
    padding-top: .25rem !important
  }

  .pt-sm-2 {
    padding-top: .5rem !important
  }

  .pt-sm-3 {
    padding-top: 1rem !important
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important
  }

  .pt-sm-5 {
    padding-top: 3rem !important
  }

  .pe-sm-0 {
    padding-right: 0 !important
  }

  .pe-sm-1 {
    padding-right: .25rem !important
  }

  .pe-sm-2 {
    padding-right: .5rem !important
  }

  .pe-sm-3 {
    padding-right: 1rem !important
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important
  }

  .pe-sm-5 {
    padding-right: 3rem !important
  }

  .pb-sm-0 {
    padding-bottom: 0 !important
  }

  .pb-sm-1 {
    padding-bottom: .25rem !important
  }

  .pb-sm-2 {
    padding-bottom: .5rem !important
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important
  }

  .ps-sm-0 {
    padding-left: 0 !important
  }

  .ps-sm-1 {
    padding-left: .25rem !important
  }

  .ps-sm-2 {
    padding-left: .5rem !important
  }

  .ps-sm-3 {
    padding-left: 1rem !important
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important
  }

  .ps-sm-5 {
    padding-left: 3rem !important
  }

  .text-sm-start {
    text-align: left !important
  }

  .text-sm-end {
    text-align: right !important
  }

  .text-sm-center {
    text-align: center !important
  }
}

@media (min-width:768px) {
  .float-md-start {
    float: left !important
  }

  .float-md-end {
    float: right !important
  }

  .float-md-none {
    float: none !important
  }

  .d-md-inline {
    display: inline !important
  }

  .d-md-inline-block {
    display: inline-block !important
  }

  .d-md-block {
    display: block !important
  }

  .d-md-grid {
    display: grid !important
  }

  .d-md-table {
    display: table !important
  }

  .d-md-table-row {
    display: table-row !important
  }

  .d-md-table-cell {
    display: table-cell !important
  }

  .d-md-flex {
    display: flex !important
  }

  .d-md-inline-flex {
    display: inline-flex !important
  }

  .d-md-none {
    display: none !important
  }

  .flex-md-fill {
    flex: 1 1 auto !important
  }

  .flex-md-row {
    flex-direction: row !important
  }

  .flex-md-column {
    flex-direction: column !important
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-md-wrap {
    flex-wrap: wrap !important
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .gap-md-0 {
    gap: 0 !important
  }

  .gap-md-1 {
    gap: .25rem !important
  }

  .gap-md-2 {
    gap: .5rem !important
  }

  .gap-md-3 {
    gap: 1rem !important
  }

  .gap-md-4 {
    gap: 1.5rem !important
  }

  .gap-md-5 {
    gap: 3rem !important
  }

  .justify-content-md-start {
    justify-content: flex-start !important
  }

  .justify-content-md-end {
    justify-content: flex-end !important
  }

  .justify-content-md-center {
    justify-content: center !important
  }

  .justify-content-md-between {
    justify-content: space-between !important
  }

  .justify-content-md-around {
    justify-content: space-around !important
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important
  }

  .align-items-md-start {
    align-items: flex-start !important
  }

  .align-items-md-end {
    align-items: flex-end !important
  }

  .align-items-md-center {
    align-items: center !important
  }

  .align-items-md-baseline {
    align-items: baseline !important
  }

  .align-items-md-stretch {
    align-items: stretch !important
  }

  .align-content-md-start {
    align-content: flex-start !important
  }

  .align-content-md-end {
    align-content: flex-end !important
  }

  .align-content-md-center {
    align-content: center !important
  }

  .align-content-md-between {
    align-content: space-between !important
  }

  .align-content-md-around {
    align-content: space-around !important
  }

  .align-content-md-stretch {
    align-content: stretch !important
  }

  .align-self-md-auto {
    align-self: auto !important
  }

  .align-self-md-start {
    align-self: flex-start !important
  }

  .align-self-md-end {
    align-self: flex-end !important
  }

  .align-self-md-center {
    align-self: center !important
  }

  .align-self-md-baseline {
    align-self: baseline !important
  }

  .align-self-md-stretch {
    align-self: stretch !important
  }

  .order-md-first {
    order: -1 !important
  }

  .order-md-0 {
    order: 0 !important
  }

  .order-md-1 {
    order: 1 !important
  }

  .order-md-2 {
    order: 2 !important
  }

  .order-md-3 {
    order: 3 !important
  }

  .order-md-4 {
    order: 4 !important
  }

  .order-md-5 {
    order: 5 !important
  }

  .order-md-last {
    order: 6 !important
  }

  .m-md-0 {
    margin: 0 !important
  }

  .m-md-1 {
    margin: .25rem !important
  }

  .m-md-2 {
    margin: .5rem !important
  }

  .m-md-3 {
    margin: 1rem !important
  }

  .m-md-4 {
    margin: 1.5rem !important
  }

  .m-md-5 {
    margin: 3rem !important
  }

  .m-md-auto {
    margin: auto !important
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-md-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-md-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-md-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-md-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-md-0 {
    margin-top: 0 !important
  }

  .mt-md-1 {
    margin-top: .25rem !important
  }

  .mt-md-2 {
    margin-top: .5rem !important
  }

  .mt-md-3 {
    margin-top: 1rem !important
  }

  .mt-md-4 {
    margin-top: 1.5rem !important
  }

  .mt-md-5 {
    margin-top: 3rem !important
  }

  .mt-md-auto {
    margin-top: auto !important
  }

  .me-md-0 {
    margin-right: 0 !important
  }

  .me-md-1 {
    margin-right: .25rem !important
  }

  .me-md-2 {
    margin-right: .5rem !important
  }

  .me-md-3 {
    margin-right: 1rem !important
  }

  .me-md-4 {
    margin-right: 1.5rem !important
  }

  .me-md-5 {
    margin-right: 3rem !important
  }

  .me-md-auto {
    margin-right: auto !important
  }

  .mb-md-0 {
    margin-bottom: 0 !important
  }

  .mb-md-1 {
    margin-bottom: .25rem !important
  }

  .mb-md-2 {
    margin-bottom: .5rem !important
  }

  .mb-md-3 {
    margin-bottom: 1rem !important
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-md-5 {
    margin-bottom: 3rem !important
  }

  .mb-md-auto {
    margin-bottom: auto !important
  }

  .ms-md-0 {
    margin-left: 0 !important
  }

  .ms-md-1 {
    margin-left: .25rem !important
  }

  .ms-md-2 {
    margin-left: .5rem !important
  }

  .ms-md-3 {
    margin-left: 1rem !important
  }

  .ms-md-4 {
    margin-left: 1.5rem !important
  }

  .ms-md-5 {
    margin-left: 3rem !important
  }

  .ms-md-auto {
    margin-left: auto !important
  }

  .p-md-0 {
    padding: 0 !important
  }

  .p-md-1 {
    padding: .25rem !important
  }

  .p-md-2 {
    padding: .5rem !important
  }

  .p-md-3 {
    padding: 1rem !important
  }

  .p-md-4 {
    padding: 1.5rem !important
  }

  .p-md-5 {
    padding: 3rem !important
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-md-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-md-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-md-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-md-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-md-0 {
    padding-top: 0 !important
  }

  .pt-md-1 {
    padding-top: .25rem !important
  }

  .pt-md-2 {
    padding-top: .5rem !important
  }

  .pt-md-3 {
    padding-top: 1rem !important
  }

  .pt-md-4 {
    padding-top: 1.5rem !important
  }

  .pt-md-5 {
    padding-top: 3rem !important
  }

  .pe-md-0 {
    padding-right: 0 !important
  }

  .pe-md-1 {
    padding-right: .25rem !important
  }

  .pe-md-2 {
    padding-right: .5rem !important
  }

  .pe-md-3 {
    padding-right: 1rem !important
  }

  .pe-md-4 {
    padding-right: 1.5rem !important
  }

  .pe-md-5 {
    padding-right: 3rem !important
  }

  .pb-md-0 {
    padding-bottom: 0 !important
  }

  .pb-md-1 {
    padding-bottom: .25rem !important
  }

  .pb-md-2 {
    padding-bottom: .5rem !important
  }

  .pb-md-3 {
    padding-bottom: 1rem !important
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-md-5 {
    padding-bottom: 3rem !important
  }

  .ps-md-0 {
    padding-left: 0 !important
  }

  .ps-md-1 {
    padding-left: .25rem !important
  }

  .ps-md-2 {
    padding-left: .5rem !important
  }

  .ps-md-3 {
    padding-left: 1rem !important
  }

  .ps-md-4 {
    padding-left: 1.5rem !important
  }

  .ps-md-5 {
    padding-left: 3rem !important
  }

  .text-md-start {
    text-align: left !important
  }

  .text-md-end {
    text-align: right !important
  }

  .text-md-center {
    text-align: center !important
  }
}

@media (min-width:992px) {
  .float-lg-start {
    float: left !important
  }

  .float-lg-end {
    float: right !important
  }

  .float-lg-none {
    float: none !important
  }

  .d-lg-inline {
    display: inline !important
  }

  .d-lg-inline-block {
    display: inline-block !important
  }

  .d-lg-block {
    display: block !important
  }

  .d-lg-grid {
    display: grid !important
  }

  .d-lg-table {
    display: table !important
  }

  .d-lg-table-row {
    display: table-row !important
  }

  .d-lg-table-cell {
    display: table-cell !important
  }

  .d-lg-flex {
    display: flex !important
  }

  .d-lg-inline-flex {
    display: inline-flex !important
  }

  .d-lg-none {
    display: none !important
  }

  .flex-lg-fill {
    flex: 1 1 auto !important
  }

  .flex-lg-row {
    flex-direction: row !important
  }

  .flex-lg-column {
    flex-direction: column !important
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .gap-lg-0 {
    gap: 0 !important
  }

  .gap-lg-1 {
    gap: .25rem !important
  }

  .gap-lg-2 {
    gap: .5rem !important
  }

  .gap-lg-3 {
    gap: 1rem !important
  }

  .gap-lg-4 {
    gap: 1.5rem !important
  }

  .gap-lg-5 {
    gap: 3rem !important
  }

  .justify-content-lg-start {
    justify-content: flex-start !important
  }

  .justify-content-lg-end {
    justify-content: flex-end !important
  }

  .justify-content-lg-center {
    justify-content: center !important
  }

  .justify-content-lg-between {
    justify-content: space-between !important
  }

  .justify-content-lg-around {
    justify-content: space-around !important
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important
  }

  .align-items-lg-start {
    align-items: flex-start !important
  }

  .align-items-lg-end {
    align-items: flex-end !important
  }

  .align-items-lg-center {
    align-items: center !important
  }

  .align-items-lg-baseline {
    align-items: baseline !important
  }

  .align-items-lg-stretch {
    align-items: stretch !important
  }

  .align-content-lg-start {
    align-content: flex-start !important
  }

  .align-content-lg-end {
    align-content: flex-end !important
  }

  .align-content-lg-center {
    align-content: center !important
  }

  .align-content-lg-between {
    align-content: space-between !important
  }

  .align-content-lg-around {
    align-content: space-around !important
  }

  .align-content-lg-stretch {
    align-content: stretch !important
  }

  .align-self-lg-auto {
    align-self: auto !important
  }

  .align-self-lg-start {
    align-self: flex-start !important
  }

  .align-self-lg-end {
    align-self: flex-end !important
  }

  .align-self-lg-center {
    align-self: center !important
  }

  .align-self-lg-baseline {
    align-self: baseline !important
  }

  .align-self-lg-stretch {
    align-self: stretch !important
  }

  .order-lg-first {
    order: -1 !important
  }

  .order-lg-0 {
    order: 0 !important
  }

  .order-lg-1 {
    order: 1 !important
  }

  .order-lg-2 {
    order: 2 !important
  }

  .order-lg-3 {
    order: 3 !important
  }

  .order-lg-4 {
    order: 4 !important
  }

  .order-lg-5 {
    order: 5 !important
  }

  .order-lg-last {
    order: 6 !important
  }

  .m-lg-0 {
    margin: 0 !important
  }

  .m-lg-1 {
    margin: .25rem !important
  }

  .m-lg-2 {
    margin: .5rem !important
  }

  .m-lg-3 {
    margin: 1rem !important
  }

  .m-lg-4 {
    margin: 1.5rem !important
  }

  .m-lg-5 {
    margin: 3rem !important
  }

  .m-lg-auto {
    margin: auto !important
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-lg-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-lg-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-lg-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-lg-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-lg-0 {
    margin-top: 0 !important
  }

  .mt-lg-1 {
    margin-top: .25rem !important
  }

  .mt-lg-2 {
    margin-top: .5rem !important
  }

  .mt-lg-3 {
    margin-top: 1rem !important
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important
  }

  .mt-lg-5 {
    margin-top: 3rem !important
  }

  .mt-lg-auto {
    margin-top: auto !important
  }

  .me-lg-0 {
    margin-right: 0 !important
  }

  .me-lg-1 {
    margin-right: .25rem !important
  }

  .me-lg-2 {
    margin-right: .5rem !important
  }

  .me-lg-3 {
    margin-right: 1rem !important
  }

  .me-lg-4 {
    margin-right: 1.5rem !important
  }

  .me-lg-5 {
    margin-right: 3rem !important
  }

  .me-lg-auto {
    margin-right: auto !important
  }

  .mb-lg-0 {
    margin-bottom: 0 !important
  }

  .mb-lg-1 {
    margin-bottom: .25rem !important
  }

  .mb-lg-2 {
    margin-bottom: .5rem !important
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important
  }

  .mb-lg-auto {
    margin-bottom: auto !important
  }

  .ms-lg-0 {
    margin-left: 0 !important
  }

  .ms-lg-1 {
    margin-left: .25rem !important
  }

  .ms-lg-2 {
    margin-left: .5rem !important
  }

  .ms-lg-3 {
    margin-left: 1rem !important
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important
  }

  .ms-lg-5 {
    margin-left: 3rem !important
  }

  .ms-lg-auto {
    margin-left: auto !important
  }

  .p-lg-0 {
    padding: 0 !important
  }

  .p-lg-1 {
    padding: .25rem !important
  }

  .p-lg-2 {
    padding: .5rem !important
  }

  .p-lg-3 {
    padding: 1rem !important
  }

  .p-lg-4 {
    padding: 1.5rem !important
  }

  .p-lg-5 {
    padding: 3rem !important
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-lg-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-lg-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-lg-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-lg-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-lg-0 {
    padding-top: 0 !important
  }

  .pt-lg-1 {
    padding-top: .25rem !important
  }

  .pt-lg-2 {
    padding-top: .5rem !important
  }

  .pt-lg-3 {
    padding-top: 1rem !important
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important
  }

  .pt-lg-5 {
    padding-top: 3rem !important
  }

  .pe-lg-0 {
    padding-right: 0 !important
  }

  .pe-lg-1 {
    padding-right: .25rem !important
  }

  .pe-lg-2 {
    padding-right: .5rem !important
  }

  .pe-lg-3 {
    padding-right: 1rem !important
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important
  }

  .pe-lg-5 {
    padding-right: 3rem !important
  }

  .pb-lg-0 {
    padding-bottom: 0 !important
  }

  .pb-lg-1 {
    padding-bottom: .25rem !important
  }

  .pb-lg-2 {
    padding-bottom: .5rem !important
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important
  }

  .ps-lg-0 {
    padding-left: 0 !important
  }

  .ps-lg-1 {
    padding-left: .25rem !important
  }

  .ps-lg-2 {
    padding-left: .5rem !important
  }

  .ps-lg-3 {
    padding-left: 1rem !important
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important
  }

  .ps-lg-5 {
    padding-left: 3rem !important
  }

  .text-lg-start {
    text-align: left !important
  }

  .text-lg-end {
    text-align: right !important
  }

  .text-lg-center {
    text-align: center !important
  }
}

@media (min-width:1200px) {
  .float-xl-start {
    float: left !important
  }

  .float-xl-end {
    float: right !important
  }

  .float-xl-none {
    float: none !important
  }

  .d-xl-inline {
    display: inline !important
  }

  .d-xl-inline-block {
    display: inline-block !important
  }

  .d-xl-block {
    display: block !important
  }

  .d-xl-grid {
    display: grid !important
  }

  .d-xl-table {
    display: table !important
  }

  .d-xl-table-row {
    display: table-row !important
  }

  .d-xl-table-cell {
    display: table-cell !important
  }

  .d-xl-flex {
    display: flex !important
  }

  .d-xl-inline-flex {
    display: inline-flex !important
  }

  .d-xl-none {
    display: none !important
  }

  .flex-xl-fill {
    flex: 1 1 auto !important
  }

  .flex-xl-row {
    flex-direction: row !important
  }

  .flex-xl-column {
    flex-direction: column !important
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .gap-xl-0 {
    gap: 0 !important
  }

  .gap-xl-1 {
    gap: .25rem !important
  }

  .gap-xl-2 {
    gap: .5rem !important
  }

  .gap-xl-3 {
    gap: 1rem !important
  }

  .gap-xl-4 {
    gap: 1.5rem !important
  }

  .gap-xl-5 {
    gap: 3rem !important
  }

  .justify-content-xl-start {
    justify-content: flex-start !important
  }

  .justify-content-xl-end {
    justify-content: flex-end !important
  }

  .justify-content-xl-center {
    justify-content: center !important
  }

  .justify-content-xl-between {
    justify-content: space-between !important
  }

  .justify-content-xl-around {
    justify-content: space-around !important
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important
  }

  .align-items-xl-start {
    align-items: flex-start !important
  }

  .align-items-xl-end {
    align-items: flex-end !important
  }

  .align-items-xl-center {
    align-items: center !important
  }

  .align-items-xl-baseline {
    align-items: baseline !important
  }

  .align-items-xl-stretch {
    align-items: stretch !important
  }

  .align-content-xl-start {
    align-content: flex-start !important
  }

  .align-content-xl-end {
    align-content: flex-end !important
  }

  .align-content-xl-center {
    align-content: center !important
  }

  .align-content-xl-between {
    align-content: space-between !important
  }

  .align-content-xl-around {
    align-content: space-around !important
  }

  .align-content-xl-stretch {
    align-content: stretch !important
  }

  .align-self-xl-auto {
    align-self: auto !important
  }

  .align-self-xl-start {
    align-self: flex-start !important
  }

  .align-self-xl-end {
    align-self: flex-end !important
  }

  .align-self-xl-center {
    align-self: center !important
  }

  .align-self-xl-baseline {
    align-self: baseline !important
  }

  .align-self-xl-stretch {
    align-self: stretch !important
  }

  .order-xl-first {
    order: -1 !important
  }

  .order-xl-0 {
    order: 0 !important
  }

  .order-xl-1 {
    order: 1 !important
  }

  .order-xl-2 {
    order: 2 !important
  }

  .order-xl-3 {
    order: 3 !important
  }

  .order-xl-4 {
    order: 4 !important
  }

  .order-xl-5 {
    order: 5 !important
  }

  .order-xl-last {
    order: 6 !important
  }

  .m-xl-0 {
    margin: 0 !important
  }

  .m-xl-1 {
    margin: .25rem !important
  }

  .m-xl-2 {
    margin: .5rem !important
  }

  .m-xl-3 {
    margin: 1rem !important
  }

  .m-xl-4 {
    margin: 1.5rem !important
  }

  .m-xl-5 {
    margin: 3rem !important
  }

  .m-xl-auto {
    margin: auto !important
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-xl-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-xl-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-xl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-xl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-xl-0 {
    margin-top: 0 !important
  }

  .mt-xl-1 {
    margin-top: .25rem !important
  }

  .mt-xl-2 {
    margin-top: .5rem !important
  }

  .mt-xl-3 {
    margin-top: 1rem !important
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important
  }

  .mt-xl-5 {
    margin-top: 3rem !important
  }

  .mt-xl-auto {
    margin-top: auto !important
  }

  .me-xl-0 {
    margin-right: 0 !important
  }

  .me-xl-1 {
    margin-right: .25rem !important
  }

  .me-xl-2 {
    margin-right: .5rem !important
  }

  .me-xl-3 {
    margin-right: 1rem !important
  }

  .me-xl-4 {
    margin-right: 1.5rem !important
  }

  .me-xl-5 {
    margin-right: 3rem !important
  }

  .me-xl-auto {
    margin-right: auto !important
  }

  .mb-xl-0 {
    margin-bottom: 0 !important
  }

  .mb-xl-1 {
    margin-bottom: .25rem !important
  }

  .mb-xl-2 {
    margin-bottom: .5rem !important
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important
  }

  .mb-xl-auto {
    margin-bottom: auto !important
  }

  .ms-xl-0 {
    margin-left: 0 !important
  }

  .ms-xl-1 {
    margin-left: .25rem !important
  }

  .ms-xl-2 {
    margin-left: .5rem !important
  }

  .ms-xl-3 {
    margin-left: 1rem !important
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important
  }

  .ms-xl-5 {
    margin-left: 3rem !important
  }

  .ms-xl-auto {
    margin-left: auto !important
  }

  .p-xl-0 {
    padding: 0 !important
  }

  .p-xl-1 {
    padding: .25rem !important
  }

  .p-xl-2 {
    padding: .5rem !important
  }

  .p-xl-3 {
    padding: 1rem !important
  }

  .p-xl-4 {
    padding: 1.5rem !important
  }

  .p-xl-5 {
    padding: 3rem !important
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-xl-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-xl-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-xl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-xl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-xl-0 {
    padding-top: 0 !important
  }

  .pt-xl-1 {
    padding-top: .25rem !important
  }

  .pt-xl-2 {
    padding-top: .5rem !important
  }

  .pt-xl-3 {
    padding-top: 1rem !important
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important
  }

  .pt-xl-5 {
    padding-top: 3rem !important
  }

  .pe-xl-0 {
    padding-right: 0 !important
  }

  .pe-xl-1 {
    padding-right: .25rem !important
  }

  .pe-xl-2 {
    padding-right: .5rem !important
  }

  .pe-xl-3 {
    padding-right: 1rem !important
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important
  }

  .pe-xl-5 {
    padding-right: 3rem !important
  }

  .pb-xl-0 {
    padding-bottom: 0 !important
  }

  .pb-xl-1 {
    padding-bottom: .25rem !important
  }

  .pb-xl-2 {
    padding-bottom: .5rem !important
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important
  }

  .ps-xl-0 {
    padding-left: 0 !important
  }

  .ps-xl-1 {
    padding-left: .25rem !important
  }

  .ps-xl-2 {
    padding-left: .5rem !important
  }

  .ps-xl-3 {
    padding-left: 1rem !important
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important
  }

  .ps-xl-5 {
    padding-left: 3rem !important
  }

  .text-xl-start {
    text-align: left !important
  }

  .text-xl-end {
    text-align: right !important
  }

  .text-xl-center {
    text-align: center !important
  }
}

@media (min-width:1400) {
  .float-xxl-start {
    float: left !important
  }

  .float-xxl-end {
    float: right !important
  }

  .float-xxl-none {
    float: none !important
  }

  .d-xxl-inline {
    display: inline !important
  }

  .d-xxl-inline-block {
    display: inline-block !important
  }

  .d-xxl-block {
    display: block !important
  }

  .d-xxl-grid {
    display: grid !important
  }

  .d-xxl-table {
    display: table !important
  }

  .d-xxl-table-row {
    display: table-row !important
  }

  .d-xxl-table-cell {
    display: table-cell !important
  }

  .d-xxl-flex {
    display: flex !important
  }

  .d-xxl-inline-flex {
    display: inline-flex !important
  }

  .d-xxl-none {
    display: none !important
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important
  }

  .flex-xxl-row {
    flex-direction: row !important
  }

  .flex-xxl-column {
    flex-direction: column !important
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }

  .gap-xxl-0 {
    gap: 0 !important
  }

  .gap-xxl-1 {
    gap: .25rem !important
  }

  .gap-xxl-2 {
    gap: .5rem !important
  }

  .gap-xxl-3 {
    gap: 1rem !important
  }

  .gap-xxl-4 {
    gap: 1.5rem !important
  }

  .gap-xxl-5 {
    gap: 3rem !important
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important
  }

  .justify-content-xxl-center {
    justify-content: center !important
  }

  .justify-content-xxl-between {
    justify-content: space-between !important
  }

  .justify-content-xxl-around {
    justify-content: space-around !important
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important
  }

  .align-items-xxl-start {
    align-items: flex-start !important
  }

  .align-items-xxl-end {
    align-items: flex-end !important
  }

  .align-items-xxl-center {
    align-items: center !important
  }

  .align-items-xxl-baseline {
    align-items: baseline !important
  }

  .align-items-xxl-stretch {
    align-items: stretch !important
  }

  .align-content-xxl-start {
    align-content: flex-start !important
  }

  .align-content-xxl-end {
    align-content: flex-end !important
  }

  .align-content-xxl-center {
    align-content: center !important
  }

  .align-content-xxl-between {
    align-content: space-between !important
  }

  .align-content-xxl-around {
    align-content: space-around !important
  }

  .align-content-xxl-stretch {
    align-content: stretch !important
  }

  .align-self-xxl-auto {
    align-self: auto !important
  }

  .align-self-xxl-start {
    align-self: flex-start !important
  }

  .align-self-xxl-end {
    align-self: flex-end !important
  }

  .align-self-xxl-center {
    align-self: center !important
  }

  .align-self-xxl-baseline {
    align-self: baseline !important
  }

  .align-self-xxl-stretch {
    align-self: stretch !important
  }

  .order-xxl-first {
    order: -1 !important
  }

  .order-xxl-0 {
    order: 0 !important
  }

  .order-xxl-1 {
    order: 1 !important
  }

  .order-xxl-2 {
    order: 2 !important
  }

  .order-xxl-3 {
    order: 3 !important
  }

  .order-xxl-4 {
    order: 4 !important
  }

  .order-xxl-5 {
    order: 5 !important
  }

  .order-xxl-last {
    order: 6 !important
  }

  .m-xxl-0 {
    margin: 0 !important
  }

  .m-xxl-1 {
    margin: .25rem !important
  }

  .m-xxl-2 {
    margin: .5rem !important
  }

  .m-xxl-3 {
    margin: 1rem !important
  }

  .m-xxl-4 {
    margin: 1.5rem !important
  }

  .m-xxl-5 {
    margin: 3rem !important
  }

  .m-xxl-auto {
    margin: auto !important
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }

  .mx-xxl-1 {
    margin-right: .25rem !important;
    margin-left: .25rem !important
  }

  .mx-xxl-2 {
    margin-right: .5rem !important;
    margin-left: .5rem !important
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }

  .my-xxl-1 {
    margin-top: .25rem !important;
    margin-bottom: .25rem !important
  }

  .my-xxl-2 {
    margin-top: .5rem !important;
    margin-bottom: .5rem !important
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }

  .mt-xxl-0 {
    margin-top: 0 !important
  }

  .mt-xxl-1 {
    margin-top: .25rem !important
  }

  .mt-xxl-2 {
    margin-top: .5rem !important
  }

  .mt-xxl-3 {
    margin-top: 1rem !important
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important
  }

  .mt-xxl-5 {
    margin-top: 3rem !important
  }

  .mt-xxl-auto {
    margin-top: auto !important
  }

  .me-xxl-0 {
    margin-right: 0 !important
  }

  .me-xxl-1 {
    margin-right: .25rem !important
  }

  .me-xxl-2 {
    margin-right: .5rem !important
  }

  .me-xxl-3 {
    margin-right: 1rem !important
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important
  }

  .me-xxl-5 {
    margin-right: 3rem !important
  }

  .me-xxl-auto {
    margin-right: auto !important
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important
  }

  .mb-xxl-1 {
    margin-bottom: .25rem !important
  }

  .mb-xxl-2 {
    margin-bottom: .5rem !important
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important
  }

  .mb-xxl-auto {
    margin-bottom: auto !important
  }

  .ms-xxl-0 {
    margin-left: 0 !important
  }

  .ms-xxl-1 {
    margin-left: .25rem !important
  }

  .ms-xxl-2 {
    margin-left: .5rem !important
  }

  .ms-xxl-3 {
    margin-left: 1rem !important
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important
  }

  .ms-xxl-5 {
    margin-left: 3rem !important
  }

  .ms-xxl-auto {
    margin-left: auto !important
  }

  .p-xxl-0 {
    padding: 0 !important
  }

  .p-xxl-1 {
    padding: .25rem !important
  }

  .p-xxl-2 {
    padding: .5rem !important
  }

  .p-xxl-3 {
    padding: 1rem !important
  }

  .p-xxl-4 {
    padding: 1.5rem !important
  }

  .p-xxl-5 {
    padding: 3rem !important
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }

  .px-xxl-1 {
    padding-right: .25rem !important;
    padding-left: .25rem !important
  }

  .px-xxl-2 {
    padding-right: .5rem !important;
    padding-left: .5rem !important
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }

  .py-xxl-1 {
    padding-top: .25rem !important;
    padding-bottom: .25rem !important
  }

  .py-xxl-2 {
    padding-top: .5rem !important;
    padding-bottom: .5rem !important
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important
  }

  .pt-xxl-0 {
    padding-top: 0 !important
  }

  .pt-xxl-1 {
    padding-top: .25rem !important
  }

  .pt-xxl-2 {
    padding-top: .5rem !important
  }

  .pt-xxl-3 {
    padding-top: 1rem !important
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important
  }

  .pt-xxl-5 {
    padding-top: 3rem !important
  }

  .pe-xxl-0 {
    padding-right: 0 !important
  }

  .pe-xxl-1 {
    padding-right: .25rem !important
  }

  .pe-xxl-2 {
    padding-right: .5rem !important
  }

  .pe-xxl-3 {
    padding-right: 1rem !important
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important
  }

  .pe-xxl-5 {
    padding-right: 3rem !important
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important
  }

  .pb-xxl-1 {
    padding-bottom: .25rem !important
  }

  .pb-xxl-2 {
    padding-bottom: .5rem !important
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important
  }

  .ps-xxl-0 {
    padding-left: 0 !important
  }

  .ps-xxl-1 {
    padding-left: .25rem !important
  }

  .ps-xxl-2 {
    padding-left: .5rem !important
  }

  .ps-xxl-3 {
    padding-left: 1rem !important
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important
  }

  .ps-xxl-5 {
    padding-left: 3rem !important
  }

  .text-xxl-start {
    text-align: left !important
  }

  .text-xxl-end {
    text-align: right !important
  }

  .text-xxl-center {
    text-align: center !important
  }
}

@media (min-width:1200px) {
  .fs-1 {
    font-size: 1.5rem !important
  }
}

@media print {
  .d-print-inline {
    display: inline !important
  }

  .d-print-inline-block {
    display: inline-block !important
  }

  .d-print-block {
    display: block !important
  }

  .d-print-grid {
    display: grid !important
  }

  .d-print-table {
    display: table !important
  }

  .d-print-table-row {
    display: table-row !important
  }

  .d-print-table-cell {
    display: table-cell !important
  }

  .d-print-flex {
    display: flex !important
  }

  .d-print-inline-flex {
    display: inline-flex !important
  }

  .d-print-none {
    display: none !important
  }
}

* {
  outline: 0;
  padding: 0
}

::after {
  margin: 0;
  padding: 0
}

::before {
  margin: 0;
  padding: 0
}

body {
  line-height: 1.75
}

body.fixed {
  overflow: hidden;
  position: fixed
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: 400
}

ul {
  padding: 0;
  margin: 0
}

li {
  list-style: none
}

a {
  text-decoration: none;
  outline: 0
}

a.active, a:focus, a:hover {
  text-decoration: none;
  outline: 0;
  color: #1e74fd
}

.strong, b, strong {
  font-weight: 700
}

.h-0 {
  height: 0
}

.main-wrap, .main-wrapper {
  opacity: 1;
  transition: all .25s ease-in;
  overflow: hidden;
  position: relative;
  z-index: 1
}

.main-wrap.show, .main-wrapper.show {
  opacity: 1
}

.content-body {
  margin-left: 65px;
  margin-top: 18px;
  margin-right: 6px;
  margin-bottom: 45px
}

.text-grey-100 {
  color: #f8f9fa !important
}

.text-grey-200 {
  color: #e9ecef !important
}

.text-grey-300 {
  color: #dee2e6 !important
}

.text-grey-400 {
  color: #ced4da !important
}

.text-grey-500 {
  color: #adb5bd !important
}

.text-grey-600 {
  color: #6c757d !important
}

.text-grey-700 {
  color: #495057 !important
}

.text-grey-800 {
  color: #343a40 !important
}

.text-grey-900 {
  color: #212529 !important
}

.fw-100 {
  font-weight: 100 !important
}

.fw-200 {
  font-weight: 200 !important
}

.fw-300 {
  font-weight: 300 !important
}

.fw-400 {
  font-weight: 400 !important
}

.fw-500 {
  font-weight: 500 !important
}

.fw-600 {
  font-weight: 600 !important
}

.fw-700 {
  font-weight: 700 !important
}

.fw-800 {
  font-weight: 800 !important
}

.fw-900 {
  font-weight: 900 !important
}

.swatch-primary {
  background-color: color-yiq(#1e74fd)
}

.swatch-secondary {
  background-color: color-yiq(#673bb7)
}

.swatch-success {
  background-color: color-yiq(#10d876)
}

.swatch-info {
  background-color: color-yiq(#2754e6)
}

.swatch-warning {
  background-color: color-yiq(#fe9431)
}

.swatch-danger {
  background-color: color-yiq(#e50202)
}

.swatch-light {
  background-color: color-yiq(#f7f7f7)
}

.swatch-dark {
  background-color: color-yiq(#343a40)
}

.pt150 {
  padding-top: 150px
}

.mont-font {
  font-family: Montserrat, sans-serif
}

.roboto-font {
  font-family: Roboto, sans-serif
}

.open-font {
  font-family: "Open Sans", sans-serif
}

.fredoka-font {
  font-family: "Fredoka One", cursive
}

.right-chat {
  position: fixed;
  top: 0;
  right: -290px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 290px;
  height: calc(100vh - 15px);
  overflow: hidden;
  background: 0 0;
  transition: all .25s ease;
  padding-top: calc(90px + 6px);
  padding-right: 15px;
  padding-left: 15px
}

@media (max-width:991.98px) {
  .right-chat {
    padding-top: 66px !important;
    padding-bottom: 66px;
    z-index: 10
  }
}

.right-chat.active-sidebar {
  right: 0
}

.nav-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 96px;
  padding: 0 15px;
  background: #fff;
  border-bottom: 2px #eee solid;
  -webkit-transition: left .25s;
  -o-transition: left .25s;
  transition: left .25s;
  justify-content: center;
}

@media (max-width:991.98px) {
  .nav-header {
    padding: 9px 15px 9px 15px;
    height: auto;
    position: fixed;
    top: env(safe-area-inset-top);
    left: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .03) !important
  }
}

.nav-header .mob-menu {
  display: none !important
}

@media (max-width:991.98px) {
  .nav-header .mob-menu {
    display: inline-block !important
  }
}

.nav-header .nav-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width:991.98px) {
  .nav-header .nav-top {
    position: relative;
    width: 100%
  }
}

.nav-header .nav-top a {
  line-height: 90px;
  width: 280px;
  text-align: left;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  white-space: nowrap;
  justify-content: center;
}

.nav-header .nav-top a .logo-text {
  line-height: 90px;
  transition: all .4s ease
}

@media (max-width:991.98px) {
  .nav-header .nav-top a {
    line-height: 28px;
    padding-left: 0;
    width: auto
  }

  .nav-header .nav-top a i.feather-zap {
    font-size: 28px !important;
    margin-right: 5px !important
  }

  .nav-header .nav-top a .logo-text {
    line-height: 28px;
    font-size: 24px !important
  }
}

.nav-header .nav-menu {
  display: none;
  border: 0;
  background-color: transparent !important;
  flex-shrink: 0;
  width: 27px;
  height: 27px;
  margin-right: 32px;
  font-size: 0;
  position: relative;
  transition: all .25s ease
}

.nav-header .nav-menu:focus {
  outline: 0
}

.nav-header .nav-menu:before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 2px;
  top: 7px;
  right: 0;
  position: absolute;
  border-radius: 1px;
  background: #1b1d21;
  -webkit-transition: all .25s;
  -o-transition: all .25s;
  transition: all .25s
}

.nav-header .nav-menu:after {
  content: "";
  display: inline-block;
  width: 22px;
  height: 2px;
  top: 18px;
  right: 0;
  position: absolute;
  border-radius: 1px;
  background: #1b1d21;
  -webkit-transition: all .25s;
  -o-transition: all .25s;
  transition: all .25s
}

.nav-header .nav-menu.active {
  background-color: transparent
}

.nav-header .nav-menu.active:before {
  transform: translateY(8px) rotate(45deg)
}

.nav-header .nav-menu.active:after {
  transform: translateY(-8px) rotate(-45deg);
  top: 22px;
  width: 30px
}

@media (max-width:991.98px) {
  .nav-header .nav-menu {
    display: inline-block
  }
}

@media (max-width:991.98px) {
  .nav-header .header-search {
    display: none
  }
}

.nav-header .header-search .form-group.icon-input i {
  top: 14px
}

@media (max-width:991.98px) {
  .nav-header .menu-icon {
    display: none
  }
}

@media (max-width:1199.98px) {
  .nav-header .center-menu-icon {
    display: none
  }
}

.nav-header .menu-search-icon {
  display: none
}

@media (max-width:991.98px) {
  .nav-header .menu-search-icon {
    display: inline-block
  }
}

.navigation {
  position: fixed;
  top: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 280px;
  height: 100vh;
  overflow: hidden;
  background: 0 0;
  -webkit-transition: width .25s;
  -o-transition: width .25s;
  transition: width .25s;
  padding-top: calc(90px + 6px)
}

.navigation.menu-active {
  width: 90px
}

.navigation.menu-active .nav-content {
  width: 90px;
  padding-left: 10px;
  padding-right: 10px
}

.navigation.menu-active .nav-content ul li>a span {
  margin-left: 30px
}

.navigation.menu-active .card, .navigation.menu-active .nav-caption span, .navigation.menu-active .nav-content ul li>a span.circle-count, .navigation.menu-active .nav-top a .logo-text {
  display: none !important
}

.navigation.menu-active .nav-caption {
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center
}

.navigation.menu-active .nav-content ul.top-content li:not(.logo) a {
  padding: 7px
}

.navigation.menu-active .nav-content ul.top-content li:not(.logo) a i {
  padding: 13px;
  line-height: 16px !important
}

.navigation.menu-current-color .nav-wrap {
  background: linear-gradient(135deg, #555ECE, var(--theme-color-shade)) !important
}

.navigation.menu-current-color .nav-content ul li:not(.logo) a i, .navigation.menu-current-color .nav-content ul li:not(.logo) a span, .navigation.menu-current-color .nav-top a .logo-text {
  color: #fff !important
}

.navigation.menu-current-color .nav-caption {
  color: #fff !important;
  opacity: .6
}

.navigation.menu-current-color .nav-content ul li>a.active {
  background: var(--theme-color-tint) !important
}

@media (max-width:991.98px) {
  .navigation {
    position: fixed;
    top: 0;
    left: -320px;
    background-color: #fff !important;
    z-index: 1000;
    height: 100vh;
    padding-top: 0;
    transition: all .4s ease;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .18) !important
  }

  .navigation.nav-active {
    left: 0;
    right: 0
  }
}

.navigation .card {
  transition: all .4s ease;
  width: calc(280px - 45px)
}

.navigation .nav-top {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 96px
}

@media (max-width:767.98px) {
  .navigation .nav-top {
    position: relative
  }
}

.navigation .nav-top a {
  line-height: 90px;
  width: 280px;
  text-align: left;
  padding-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  white-space: nowrap
}

.navigation .nav-top a .logo-text {
  line-height: 90px;
  transition: all .4s ease
}

.navigation .nav-caption {
  margin-bottom: 5px;
  padding-left: 25px;
  white-space: nowrap;
  color: #808191;
  -webkit-transition: padding .25s;
  -o-transition: padding .25s;
  transition: padding .25s
}

.navigation .nav-content {
  width: 280px;
  transition: all .2s ease;
  padding-left: 15px;
  padding-right: 15px
}

@media (max-width:767.98px) {
  .navigation .nav-content {
    width: auto
  }

  .navigation .nav-content .nav-wrap {
    box-shadow: none !important
  }
}

@media (min-width:992px) {
  .navigation .nav-content {
    -webkit-border-radius: 0 10px 10px 0;
    -moz-border-radius: 0 10px 10px 0;
    border-radius: 0 10px 10px 0
  }
}

.navigation .nav-content ul {
  padding: 0;
  margin: 0
}

@media (min-width:992px) {
  .navigation .nav-content ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column
  }
}

@media (min-width:992px) {
  .navigation .nav-content ul li.flex-lg-brackets {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
  }
}

.navigation .nav-content ul li:not(.logo) {
  list-style-image: none;
  list-style-type: none;
  margin: 1px 5px
}

@media (max-width:767.98px) {
  .navigation .nav-content ul li:not(.logo) {
    margin: 4px 0
  }
}

.navigation .nav-content ul li:not(.logo) a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 15px 12px 15px;
  white-space: nowrap;
  position: relative;
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #888;
  height: 54px;
  display: flex;
  overflow: hidden;
  transition: all .2s ease
}

.navigation .nav-content ul li:not(.logo) a span {
  font-size: 15px;
  font-weight: 600;
  line-height: 28px;
  color: #888;
  transition: all .2s ease;
  margin-right: auto;
  padding-right: 10px
}

.navigation .nav-content ul li:not(.logo) a i {
  font-size: 20px;
  color: #fff;
  top: 0;
  position: relative
}

.navigation .nav-content ul li:not(.logo) a .circle-icon {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  padding: 0;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  position: absolute;
  top: 6px;
  right: 13px
}

.navigation .nav-content ul li:not(.logo) a .circle-count {
  padding: 1px 6px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  position: absolute;
  top: 6px;
  display: inline-block;
  color: #fff;
  font-size: 13px;
  right: 13px
}

.navigation .nav-content ul li.brackets {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

.navigation .nav-content ul li>a.active {
  background: linear-gradient(135deg, #555ECE, var(--theme-color-shade)) !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px
}

.navigation .nav-content ul li>a.active span {
  color: #fff
}

.navigation .nav-content ul li>a.active i {
  color: #fff
}

.navigation .nav-content ul li>a:hover span {
  color: #555ECE
}

.navigation .nav-content ul li>a.active:hover span {
  color: #fff !important
}

.app-footer {
  display: none
}

@media (max-width:991.98px) {
  .app-footer {
    min-height: 56px;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #fff;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: env(safe-area-inset-bottom)
  }

  .app-footer .cart-count {
    position: absolute;
    top: 2px;
    right: 10px;
    border-radius: 15px;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    display: inline-block;
    font-size: 10px
  }

  .app-footer .dot-count {
    position: absolute;
    top: 5px;
    right: 15px;
    border-radius: 5px;
    width: 7px;
    height: 7px;
    line-height: 15px;
    text-align: center;
    display: inline-block;
    font-size: 10px
  }

  .app-footer a {
    padding: 6px 20px
  }

  .app-footer a i {
    color: #fff;
    font-size: 25px;
    top: 3px;
    position: relative
  }

  .app-footer a span {
    color: #555ECE
  }
}

.app-header-search {
  display: block;
  padding: 0;
  background: #fff;
  border-bottom: 1px solid #fff;
  position: fixed;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .1), 0 1px 3px 0 rgba(0, 0, 0, .08);
  left: 0;
  top: -50%;
  right: 0;
  width: 100%;
  z-index: 1001;
  transition: .2s all ease-in-out
}

.app-header-search.show {
  top: env(safe-area-inset-top);
  transition: .3s all
}

.live-stream {
  height: calc(100vh - 137px)
}

@media (max-width:1199.98px) {
  .live-stream {
    height: 500px
  }
}

@media (max-width:991.98px) {
  .live-stream {
    height: 500px
  }
}

@media (max-width:767.98px) {
  .live-stream {
    height: 400px
  }
}

.main-content {
  background-color: #fbfcfe
}

@media (min-width:992px) {
  .main-content {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-top: 90px;


    -webkit-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s
  }
}

.main-content:not(.right-chat-active) {
  padding-right: 0
}

.main-content.menu-active {
  padding-left: 90px
}

.main-content.menu-active .middle-sidebar-header.sticky-header {
  left: 90px
}

@media (max-width:767.98px) {
  .main-content {
    order: 1;
    position: relative;
    height: calc(100vh - 56px);
    overflow-x: hidden;
    overflow-y: auto
  }
}

.main-content .left-sidebar, .main-content .right-sidebar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

.main-content .left-sidebar .left-sidebar-content, .main-content .right-sidebar .left-sidebar-content {
  background: var(--theme-color-shade);
  overflow: hidden;
  width: 270px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0 10px 10px 10px
}

.main-content .left-sidebar .left-sidebar-content .left-sidebar-header, .main-content .right-sidebar .left-sidebar-content .left-sidebar-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 13px 15px 0 15px;
  background: var(--theme-color-shade)
}

@media (max-width:575.98px) {
  .main-content .left-sidebar .left-sidebar-content .left-sidebar-header, .main-content .right-sidebar .left-sidebar-content .left-sidebar-header {
    padding: 20px 20px 0 20px
  }
}

.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
  background: var(--theme-color-shade);
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 20px 15px 15px 15px
}

@media (max-width:1199.98px) {
  .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
    width: 400px
  }
}

@media (max-width:767.98px) {
  .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
    width: 100%
  }
}

@media (max-width:575.98px) {
  .main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom {
    padding: 0 20px 0 20px
  }
}

.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-title a i, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-title a i {
  position: relative;
  top: 3px
}

.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul {
  padding-left: 31px
}

.main-content .left-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul li a, .main-content .right-sidebar .left-sidebar-content .left-sidebar-bottom .panel .panel-body ul li a {
  font-size: 12px
}

.main-content .middle-sidebar-bottom {
  width: 100%;
  padding: 20px 0 10px 0
}

@media (min-width:1200px) {
  .main-content .middle-sidebar-bottom {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1
  }
}

@media (max-width:991.98px) {
  .main-content .middle-sidebar-bottom {
    width: 100%;
    padding: 85px 15px 0
  }
}

.main-content .middle-sidebar-bottom .middle-sidebar-left {
  width: 100%
}

.main-content .middle-sidebar-bottom .middle-sidebar-right {
  z-index: 2;
  overflow-x: hidden
}

@media (max-width:991.98px) {
  .main-content .middle-sidebar-bottom .middle-sidebar-right {
    position: fixed;
    top: 0;
    right: -350px;
    z-index: 2;
    padding: 76px 15px 56px;
    height: calc(100vh);
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, .3)
  }

  .main-content .middle-sidebar-bottom .middle-sidebar-right.active-sidebar {
    right: 0;
    width: 100%
  }
}

.main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content {
  padding: 0 2px 0 0;
  width: 320px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 0
}

.main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content .card {
  border: 1px #eee solid !important
}

@media (max-width:767.98px) {
  .main-content .middle-sidebar-bottom .middle-sidebar-right .middle-sidebar-right-content {
    margin-left: auto
  }
}

.main-content .middle-sidebar-bottom .sidebar-right {
  background-color: #1a73e9;
  position: fixed;
  top: 47%;
  right: -27px;
  transform: translateY(-47%);
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  padding: 0;
  border-radius: 50%;
  z-index: 222;
  visibility: hidden;
  opacity: 0
}

.main-content .middle-sidebar-bottom .sidebar-right i {
  font-size: 18px;
  position: relative;
  left: -10px
}

body.theme-full .nav-header {
  max-width: 1600px;
  margin: 0 auto
}

body:not(.theme-full) .main-content .middle-sidebar-header.sticky-header {
  position: fixed
}

body:not(.theme-full) .main-content .middle-sidebar-bottom.header-padding {
  padding-top: 116px
}

.accordion-toggle {
  position: relative;
  display: block;
  line-height: 25px
}

.accordion-toggle.icon-none:after {
  display: none
}

.accordion-toggle:after {
  font-family: themify;
  position: absolute;
  content: "";
  right: -10px;
  top: 1px;
  color: #999;
  font-weight: lighter;
  transform: rotate(-180deg);
  transition: all .2s ease-in
}

.accordion-toggle.collapsed::after {
  color: #999;
  transform: rotate(0);
  transition: all .2s ease-in
}

.navbar-toggler {
  color: rgba(0, 0, 0, .5);
  border-color: rgba(0, 0, 0, .1);
  display: none;
  height: 40px
}

@media only screen and (max-width:992px) {
  .navbar-toggler {
    display: inline-block
  }
}

.navbar-toggler-icon {
  width: 27px;
  height: 2px;
  background-color: #999;
  position: relative;
  top: -2px
}

.navbar-toggler-icon:after {
  content: "";
  width: 27px;
  height: 2px;
  background-color: #999;
  position: absolute;
  top: -8px;
  left: 0
}

.navbar-toggler-icon:before {
  content: "";
  width: 27px;
  height: 2px;
  background-color: #999;
  position: absolute;
  bottom: -8px;
  left: 0
}

.icon-count {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  top: -10px;
  right: -10px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  color: #fff
}

.upper-header {
  background-color: #f9f9f9 !important
}

.upper-header ul {
  margin-bottom: 0;
  margin-top: 20px
}

.upper-header ul li {
  position: relative;
  padding-left: 15px
}

.upper-header ul li a {
  color: #ddd;
  font-size: 11px;
  line-height: 38px;
  font-weight: 600;
  text-transform: uppercase
}

.upper-header ul li a i {
  position: relative;
  top: 2px
}

.upper-header.bg-greylight ul li a {
  color: #999
}

.header-wrapper {
  width: 100%
}

.header-wrapper.pos-fixed {
  position: fixed;
  top: 0;
  z-index: 5
}

@media only screen and (max-width:992px) {
  .header-wrapper.pos-fixed {
    position: relative
  }
}

@media only screen and (max-width:768px) {
  .header-wrapper.pos-fixed .navbar-collapse {
    background-color: #fff;
    padding: 0 10px;
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .08) !important
  }
}

.header-wrapper.shadow-xs {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .03) !important
}

.header-wrapper .nav-menu {
  display: inline-block;
  float: left;
  margin-left: 40px;
  margin-top: 0
}

@media only screen and (max-width:992px) {
  .header-wrapper .nav-menu {
    margin-left: 0;
    width: 100%;
    display: block
  }
}

.header-wrapper .nav-menu.dropdown-menu-full {
  position: relative
}

.header-wrapper .nav-menu.dropdown-menu-full li {
  position: initial
}

.header-wrapper .nav-menu.text-white li a {
  color: #fff !important
}

.header-wrapper .nav-menu.text-black li a {
  color: #000 !important
}

.header-wrapper .nav-menu li {
  padding: 0 15px;
  display: inline-block;
  float: left
}

@media only screen and (max-width:992px) {
  .header-wrapper .nav-menu li {
    padding: 15px 0;
    text-align: left;
    width: 100%;
    margin: 0
  }
}

.header-wrapper .nav-menu li:hover .dropdown-menu {
  opacity: 1;
  visibility: visible
}

.header-wrapper .nav-menu li .dropdown-menu {
  position: absolute;
  display: block;
  left: 20px;
  top: 148%;
  border: 0;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .08) !important;
  min-width: 13rem;
  border-top: 3px #555ECE solid;
  border-radius: 0 0 5px 5px;
  opacity: 0;
  visibility: hidden;
  z-index: 1030;
  transition: all .2s ease-in-out
}

@media only screen and (max-width:992px) {
  .header-wrapper .nav-menu li .dropdown-menu {
    position: relative;
    left: 0;
    top: 0;
    display: none;
    visibility: visible;
    opacity: 1;
    border-top: 0 solid #000;
    box-shadow: none !important;
    background-color: #f5f5f5;
    padding: 0;
    margin-top: 15px
  }

  .header-wrapper .nav-menu li .dropdown-menu.show {
    display: block
  }
}

.header-wrapper .nav-menu li .dropdown-menu a {
  padding: 12px 15px;
  font-size: 14px
}

.header-wrapper .nav-menu li .dropdown-menu.full-wrap {
  min-width: 100%;
  top: 115%;
  left: 0;
  width: 100%;
  padding: 30px 30px 25px 40px;
  background-position: right 0 top 0;
  background-repeat: no-repeat;
  background-size: contain
}

.header-wrapper .nav-menu li .dropdown-menu.full-wrap ul.sub-menu {
  padding-left: 0;
  margin-left: 0;
  width: 100%;
  float: left
}

.header-wrapper .nav-menu li .dropdown-menu.full-wrap ul.sub-menu li {
  width: 100%;
  float: left;
  padding-left: 0
}

.header-wrapper .nav-menu li .dropdown-menu.full-wrap ul.sub-menu li a {
  padding-left: 0;
  font-weight: 400;
  line-height: 14px;
  display: block;
  font-size: 14px
}

.header-wrapper .nav-menu li a {
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
  letter-spacing: .4px;
  color: #777;
  transition: all .4s ease
}

.header-wrapper .nav-menu li a i {
  font-size: 10px;
  margin-left: 5px
}

@media only screen and (max-width:992px) {
  .header-wrapper .nav-menu li a i {
    float: right;
    line-height: 22px;
    margin-right: 10px
  }
}

.header-wrapper .nav-menu li a:after {
  display: none
}

.header-wrapper .nav-menu li a:hover {
  color: #555ECE
}

@media only screen and (max-width:992px) {
  .header-wrapper .nav-menu li a {
    padding: 0
  }
}

.header-wrapper .nav-menu.bg-dark li .nav-link {
  color: #eee !important;
  font-weight: 500
}

.menu-icon {
  position: relative
}

.menu-icon .dot-count {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 5px;
  width: 7px;
  height: 7px;
  line-height: 15px;
  text-align: center;
  display: inline-block;
  font-size: 10px
}

.footer-wrapper {
  margin-top: 100px;
  padding: 80px 0 10px;
  background-color: #f6f7fc
}

.footer-wrapper.bg-dark .h5, .footer-wrapper.bg-dark h5 {
  color: #fff !important
}

.footer-wrapper.bg-dark .middle-footer {
  border-top: 1px #555 solid !important
}

.footer-wrapper.bg-dark p a {
  color: #ddd !important
}

.footer-wrapper.bg-transparent {
  z-index: 100;
  position: fixed;
  left: 0;
  padding: 15px 80px;
  bottom: 0;
  margin-top: 0;
  width: 100%;
  box-shadow: none !important
}

@media only screen and (max-width:992px) {
  .footer-wrapper.bg-transparent {
    padding: 15px 15px
  }
}

@media only screen and (max-width:768px) {
  .footer-wrapper {
    margin-top: 30px;
    padding-top: 50px;
    padding-bottom: 30px
  }

  .footer-wrapper .logo img {
    width: 100px
  }
}

@media only screen and (max-width:576px) {
  .footer-wrapper {
    padding-bottom: 15px
  }
}

.footer-wrapper .icon-img {
  height: 50px
}

@media only screen and (max-width:576px) {
  .footer-wrapper .icon-img {
    margin-bottom: 20px
  }
}

.footer-wrapper form {
  overflow: hidden;
  border-radius: 2px
}

.footer-wrapper form input {
  line-height: 50px;
  width: 70%;
  background-color: #fff;
  border: 0;
  outline: 0;
  display: inline-block;
  padding: 0 15px;
  float: left;
  font-size: 14px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1)
}

.footer-wrapper form button {
  text-transform: uppercase;
  float: left;
  width: 30%;
  line-height: 50px;
  text-align: center;
  background-color: #555ECE;
  border: 0;
  outline: 0;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .1)
}

@media only screen and (max-width:768px) {
  .footer-wrapper .copyright-text {
    text-align: center;
    width: 100%;
    margin-bottom: 0;
    line-height: 22px
  }
}

.footer-wrapper .h4, .footer-wrapper h4 {
  font-size: 24px;
  line-height: 48px;
  font-weight: 300;
  color: #2f2d52;
  margin-bottom: 0
}

@media only screen and (max-width:768px) {
  .footer-wrapper .h4, .footer-wrapper h4 {
    font-size: 22px !important;
    line-height: 36px
  }
}

.footer-wrapper .h4 a, .footer-wrapper h4 a {
  font-size: 22px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  display: block;
  text-decoration: underline;
  margin-top: 20px
}

.footer-wrapper p {
  color: #aaa;
  width: 60%;
  font-size: 12px;
  font-weight: 500;
  line-height: 28px
}

.footer-wrapper p a {
  color: #333;
  font-weight: 600
}

.footer-wrapper .h5, .footer-wrapper h5 {
  font-weight: 600;
  color: #333;
  font-size: 13px;
  letter-spacing: .4px;
  text-transform: capitalize
}

.footer-wrapper ul {
  margin-bottom: 0;
  margin-top: 20px
}

.footer-wrapper ul li a {
  color: #aaa;
  font-size: 12px;
  line-height: 34px;
  font-weight: 500
}

.footer-wrapper .middle-footer {
  padding-top: 75px;
  margin-top: 75px;
  border-top: 1px #ecedf2 solid
}

@media only screen and (max-width:768px) {
  .footer-wrapper .middle-footer {
    padding-top: 30px !important;
    margin-top: 20px !important;
    padding-bottom: 0
  }
}

.footer-wrapper .lower-footer {
  padding-top: 50px
}

@media only screen and (max-width:768px) {
  .footer-wrapper .lower-footer {
    padding-top: 0
  }
}

.email-message a {
  margin-bottom: 3px;
  padding: 20px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  position: relative
}

@media (max-width:767.98px) {
  .email-message a {
    padding: 20px 10px;
    height: auto;
    display: block
  }
}

.email-message a .form-check {
  display: inline-block;
  position: relative
}

@media (max-width:767.98px) {
  .email-message a .form-check {
    display: none
  }
}

.email-message a .email-user {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 226px;
  flex: 0 0 226px;
  width: 226px;
  padding: 0 20px 0 10px
}

@media (max-width:767.98px) {
  .email-message a .email-user .btn-round-xss {
    display: none
  }
}

@media (max-width:767.98px) {
  .email-message a .email-user .h6, .email-message a .email-user h6 {
    margin-left: 4px
  }
}

@media (max-width:991.98px) {
  .email-message a .email-user {
    flex: 0 0 180px;
    width: 180px;
    padding: 0 20px 0 0
  }
}

@media (max-width:767.98px) {
  .email-message a .email-user {
    width: 100%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 0
  }
}

.email-message a .email-subject {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 210px;
  flex: 0 0 210px;
  width: 210px;
  padding: 0 20px 0 34px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  color: #44444f;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

@media (max-width:767.98px) {
  .email-message a .email-subject {
    display: none
  }
}

.email-message a .email-text {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(100% - 552px);
  flex: 0 0 calc(100% - 552px);
  max-width: calc(100% - 552px);
  padding-right: 20px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #92929d
}

@media (max-width:767.98px) {
  .email-message a .email-text {
    max-width: 100%;
    height: 46px;
    margin-top: -10px;
    padding: 0 0 0 47px;
    white-space: normal;
    line-height: 1.66667
  }
}

.email-message a .email-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  font-size: 0
}

@media (max-width:767.98px) {
  .email-message a .email-file {
    display: inline-block;
    position: absolute;
    top: 50px;
    left: 10px;
    width: auto
  }
}

.email-message a .email-time {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 72px;
  flex: 0 0 72px;
  width: 72px;
  margin-left: auto;
  text-align: right;
  font-size: 12px;
  color: #696974
}

@media (max-width:767.98px) {
  .email-message a .email-time {
    display: inline-block;
    position: absolute;
    top: 14px;
    right: 15px;
    width: auto
  }
}

.accordion {
  margin-bottom: 50px
}

.accordion .card {
  box-shadow: none;
  background: #fff;
  margin-bottom: 25px;
  border: 0
}

.accordion .card .card-body, .accordion .card .card-header {
  background: #fff;
  padding: 20px 30px;
  border-bottom: 0 solid #000
}

@media only screen and (max-width:768px) {
  .accordion .card .card-body, .accordion .card .card-header {
    padding: 15px 40px 15px 15px
  }
}

.accordion .card button {
  font-size: 14px;
  color: #444;
  font-weight: 600;
  padding-left: 0
}

.accordion .card button:focus, .accordion .card button:hover {
  text-decoration: none
}

.accordion .card button.small-text {
  font-size: 16px
}

.accordion .card button.small-text a {
  color: #111
}

.accordion .card button i {
  font-size: 30px;
  margin-right: 10px;
  position: relative;
  top: 32px;
  margin-right: 10px;
  color: #333;
  position: absolute;
  left: 33px
}

.accordion .card button span {
  font-size: 11px;
  color: #999;
  font-weight: 400;
  display: block
}

.accordion .card button:after {
  font-family: themify;
  content: "";
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 23px;
  color: #888;
  transform: rotate(223deg);
  transition: all .5s ease
}

@media only screen and (max-width:768px) {
  .accordion .card button:after {
    right: 10px
  }
}

.accordion .card button.small-text:after {
  top: 33px
}

.accordion .card button.collapsed:after {
  transform: rotate(0);
  transition: all .5s ease
}

.accordion .card p {
  font-size: 13px;
  line-height: 32px;
  color: #a1a1a1;
  font-weight: 500
}

.owl-carousel.overflow-visible .owl-stage-outer {
  overflow: visible
}

.owl-carousel.overflow-visible .owl-item {
  opacity: .2;
  transition: all .4s ease
}

.owl-carousel.overflow-visible.opacity-5 .owl-item {
  opacity: .5;
  transition: all .4s ease
}

.owl-carousel.overflow-visible .owl-item.active {
  opacity: 1
}

.owl-carousel.owl-theme .owl-dots {
  position: absolute;
  bottom: -70px;
  width: 100%
}

.owl-carousel.owl-theme .owl-dots .owl-dot span {
  width: 8px;
  height: 8px;
  margin: 4px;
  transition: all .4s ease
}

.owl-carousel.owl-theme .owl-dots .owl-dot.active span {
  background-color: #555ECE
}

.owl-carousel.owl-theme.dot-none .owl-dots {
  display: none
}

.owl-theme.owl-nav-link .owl-nav {
  position: absolute;
  top: 50%;
  width: auto;
  left: 50%;
  margin-top: 0
}

.owl-theme.owl-nav-link .owl-nav .owl-prev {
  left: -675px;
  top: 0;
  margin-top: -25px;
  position: absolute
}

.owl-theme.owl-nav-link .owl-nav .owl-next {
  right: -675px;
  top: 0;
  margin-top: -25px;
  position: absolute
}

.owl-theme.owl-nav-link .owl-nav [class*=owl-]:focus, .owl-theme.owl-nav-link .owl-nav [class*=owl-]:hover {
  background-color: transparent !important;
  outline: 0 !important
}

.owl-theme.owl-nav-link .owl-nav .icon-nav {
  font-size: 18px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  color: #000;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .03) !important
}

.owl-theme.owl-nav-link .owl-nav .icon-nav:hover {
  color: #000
}

.owl-theme.owl-nav-link.edge-link .owl-nav .icon-nav {
  font-size: 18px;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: #000;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .03) !important
}

.owl-theme.owl-nav-link.edge-link .owl-nav {
  width: 100%;
  left: 0
}

.owl-theme.owl-nav-link.edge-link .owl-nav .owl-next {
  right: 0
}

.owl-theme.owl-nav-link.edge-link .owl-nav .owl-prev {
  left: 0
}

.owl-theme.owl-nav-link.edge-link .owl-nav .icon-nav {
  box-shadow: none !important;
  margin-top: -10px
}

.owl-theme.nav-none .owl-nav {
  display: none
}

.owl-theme.dot-style2 .owl-dots {
  bottom: 20px !important
}

.owl-theme.dot-style2 .owl-dots .active span {
  background-color: transparent !important;
  border: 2px #333 solid
}

.owl-theme.dot-style2 .owl-dots span {
  width: 25px !important;
  height: 25px !important;
  padding: 10px;
  background-color: transparent;
  position: relative;
  border: 2px transparent solid
}

.owl-theme.dot-style2 .owl-dots span:after {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  content: "";
  position: absolute;
  display: inline-block;
  background-color: #555;
  top: 8px;
  left: 8px
}

.owl-theme.dot-style3 .owl-dots {
  top: -5px !important;
  text-align: left;
  width: 100%;
  bottom: auto
}

.owl-theme.dot-style3 .owl-dots .active span {
  background: rgba(255, 255, 255, .5) !important
}

.owl-theme.dot-style3 .owl-dots .active span:after {
  width: 100%;
  background-color: #fff
}

.owl-theme.dot-style3 .owl-dots .owl-dot {
  width: 23.5%;
  margin: .5%;
  border-radius: 10px
}

.owl-theme.dot-style3 .owl-dots span {
  transition: all .4s ease;
  width: 100% !important;
  height: 4px !important;
  border-radius: 10px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, .5);
  position: relative
}

.owl-theme.dot-style3 .owl-dots span:after {
  content: "";
  position: absolute;
  top: 0;
  height: 4px;
  background-color: transparent;
  width: 0%;
  left: 0;
  transition: width .2s;
  transition-duration: 4s
}

.owl-theme.right-nav .owl-nav {
  position: absolute;
  right: 0;
  top: -90px;
  margin-top: 0
}

.owl-theme.right-nav .owl-nav button {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 2px solid #555ECE
}

.owl-theme.right-nav .owl-nav button i {
  color: #555ECE;
  font-size: 18px;
  line-height: 38px
}

.owl-theme.right-nav .owl-nav button:focus, .owl-theme.right-nav .owl-nav button:hover {
  background-color: transparent !important;
  outline: 0 !important
}

.owl-theme.right-nav .owl-nav button:focus i, .owl-theme.right-nav .owl-nav button:hover i {
  color: #555ECE
}

.product-slider-3 .owl-thumbs {
  text-align: center;
  display: table;
  width: 80px;
  left: -90px;
  top: 0;
  margin-top: 0;
  position: absolute
}

.product-slider-3 .owl-thumb-item {
  width: 100%;
  height: auto;
  border: none;
  background: 0 0;
  padding: 0;
  opacity: .5;
  overflow: hidden;
  background-color: #fff
}

.product-slider-3 .owl-thumb-item img {
  width: 100%;
  height: auto;
  vertical-align: middle
}

.product-slider-3 .owl-thumb-item.active {
  opacity: 1
}

.product-slider-3 .owl-thumb-item.active img {
  position: relative
}

.product-slider-3 .owl-thumb-item:active, .product-slider-3 .owl-thumb-item:focus {
  outline: 0 !important
}

.product-slider-8 .owl-thumbs {
  text-align: center;
  display: table;
  width: 100%;
  margin-top: 10px
}

.product-slider-8 .owl-thumb-item {
  width: 20%;
  height: auto;
  border: none;
  background: 0 0;
  padding: 0;
  opacity: .5;
  overflow: hidden;
  background-color: #fff;
  margin-bottom: 10px;
  border: 2px #fff solid;
  border-radius: 5px
}

.product-slider-8 .owl-thumb-item img {
  width: 50%;
  height: auto;
  vertical-align: middle
}

.product-slider-8 .owl-thumb-item.active {
  opacity: 1
}

.product-slider-8 .owl-thumb-item.active img {
  position: relative
}

.product-slider-8 .owl-thumb-item:active, .product-slider-8 .owl-thumb-item:focus {
  outline: 0 !important
}

.owl-theme.owl-nav-link .owl-nav {
  position: absolute;
  top: 50%;
  width: 100%;
  left: 0;
  margin-top: 0
}

.owl-theme.owl-nav-link .owl-nav .owl-prev {
  left: 0;
  top: 0;
  margin-top: -25px;
  position: absolute
}

.owl-theme.owl-nav-link .owl-nav .owl-next {
  right: 0;
  top: 0;
  margin-top: -25px;
  position: absolute
}

.owl-theme.owl-nav-link .owl-nav [class*=owl-]:focus, .owl-theme.owl-nav-link .owl-nav [class*=owl-]:hover {
  background-color: transparent !important;
  outline: 0 !important
}

.owl-theme.owl-nav-link .owl-nav .icon-nav {
  font-size: 18px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50px;
  display: inline-block;
  text-align: center;
  line-height: 50px;
  color: #000;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .03) !important
}

.owl-theme.owl-nav-link .owl-nav .icon-nav:hover {
  color: #000
}

.modal-backdrop.show {
  opacity: .9
}

.modal-backdrop-none .modal-backdrop.show {
  opacity: 0 !important
}

.modal .modal-content .close {
  border: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 1;
  z-index: 2;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  display: inline-block;
  line-height: 10px
}

.modal .modal-content .close i {
  color: #fff;
  font-size: 16px
}

.modal.in .modal-dialog {
  transform: none
}

.modal.in {
  opacity: 1 !important
}

.modal-backdrop.in {
  opacity: .5
}

.modal.left.fade .modal-dialog {
  left: -280px;
  -webkit-transition: opacity .3s linear, left .3s ease-out;
  -moz-transition: opacity .3s linear, left .3s ease-out;
  -o-transition: opacity .3s linear, left .3s ease-out;
  transition: opacity .3s linear, left .3s ease-out
}

.modal.right.fade .modal-dialog {
  right: -280px;
  -webkit-transition: opacity .3s linear, right .3s ease-out;
  -moz-transition: opacity .3s linear, right .3s ease-out;
  -o-transition: opacity .3s linear, right .3s ease-out;
  transition: opacity .3s linear, right .3s ease-out
}

.modal.bottom.fade .modal-dialog {
  bottom: -50%;
  -webkit-transition: opacity .3s linear, bottom .3s ease-out;
  -moz-transition: opacity .3s linear, bottom .3s ease-out;
  -o-transition: opacity .3s linear, bottom .3s ease-out;
  transition: opacity .3s linear, bottom .3s ease-out
}

.modal.left.fade.in .modal-dialog, .modal.left.fade.show .modal-dialog {
  left: 0
}

.modal.bottom.fade.in .modal-dialog, .modal.bottom.fade.show .modal-dialog {
  bottom: 0
}

.modal.right.fade.in .modal-dialog, .modal.right.fade.show .modal-dialog {
  right: 0
}

.modal-open .modal {
  overflow-y: hidden
}

@media (min-width:576px) {
  .modal.wide.fade .modal-dialog {
    max-width: 800px
  }

  .modal.wide-xl.fade .modal-dialog {
    max-width: 1000px
  }

  .modal.side.fade .modal-dialog {
    max-width: 380px
  }

  .modal-dialog.video-wrap {
    max-width: 900px
  }

  .modal-dialog.video-wrap .close {
    right: -20px;
    top: -20px
  }
}

.toggle {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  box-sizing: border-box;
  align-self: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer
}

.toggle input[type=checkbox] {
  display: none
}

.toggle .toggle-icon {
  z-index: 0;
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  position: relative;
  transition: .3s;
  box-sizing: border-box;
  display: block;
  cursor: pointer;
  width: calc(40px);
  height: calc(20px + 2px);
  border-radius: 20px;
  background: #eee
}

.toggle .toggle-icon:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  height: calc(20px - 4px);
  width: calc(20px - 4px);
  border-radius: 18px;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
  z-index: 1;
  transition-duration: .3s;
  transform: scale(1)
}

.toggle .toggle-icon:after {
  height: calc(20px - 4px);
  width: calc(20px - 4px);
  top: 2px;
  left: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .3);
  border-radius: calc(20px - 4px);
  background: #fff;
  position: absolute;
  z-index: 2;
  transform: translateX(0);
  transition-duration: .3s;
  content: ""
}

.toggle input[type=checkbox]:checked+.toggle-icon {
  background: #ff3b30
}

.toggle input[type=checkbox]:checked+.toggle-icon:after {
  transform: translateX(20px)
}

.toggle input[type=checkbox]:checked+.toggle-icon:before {
  opacity: 0
}

.switchcolor-wrap ul {
  overflow: hidden
}

.switchcolor-wrap ul li {
  display: inline-block;
  float: left;
  margin-right: 8px
}

.switchcolor-wrap ul li .item-content {
  display: inline;
  padding-left: 0;
  position: relative
}

.switchcolor-wrap ul li .item-content .ti-check {
  left: 10px;
  top: 3px !important
}

.switchcolor-wrap ul li .item-content .ti-check:before {
  color: #fff;
  opacity: 0
}

.switchcolor-wrap .circle-color {
  width: 32px;
  height: 32px;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer
}

.switchcolor-wrap label.item-radio input[type=radio] {
  display: none
}

.switchcolor-wrap label.item-radio input[type=radio]:checked~.ti-check:before {
  opacity: 1
}

.switchcolor-wrap label.item-radio input[type=radio]~.ti-check {
  position: absolute;
  top: 50%;
  margin-top: -11px;
  right: calc(var(--f7-safe-area-right) + 10px)
}

.switchcolor-wrap .toggle-div {
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, .15);
  border: 1px #eee solid
}

.switchcolor-wrap .sheet-close {
  position: absolute;
  top: 15px;
  right: 10px;
  color: #444
}

.switchcolor-wrap .sheet-close i {
  font-size: 20px
}

.dropdown-menu-settings {
  visibility: hidden;
  opacity: 0;
  background-color: #fff;
  width: 300px;
  padding: 25px 30px;
  box-shadow: 0 15px 125px rgba(0, 0, 0, .22);
  border-radius: 6px;
  z-index: 999;
  border: 0;
  position: absolute;
  top: 100%;
  right: 0;
  text-align: left;
  transition: all .4s ease
}

.dropdown-menu-settings.active {
  visibility: visible;
  opacity: 1
}

.dropdown-menu-settings:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 10px 15px 10px;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  top: -10px;
  right: 10px;
  display: inline-block
}

.timer {
  display: block;
  position: relative;
  float: left
}

.timer.style3 .time-count {
  max-width: 25%;
  width: 25%;
  margin: 0;
  padding: 0;
  text-align: left
}

.timer.style3 .time-count .text-time {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  margin-left: 0 !important;
  margin-right: 20px !important;
  margin-bottom: 0 !important;
  text-align: left;
  background: 0 0;
  padding-bottom: 0 !important;
  padding-left: 0 !important
}

@media only screen and (max-width:992px) {
  .timer.style3 .time-count .text-time {
    font-size: 30px
  }
}

.timer.style3 .time-count .text-day {
  text-align: left;
  margin-left: 0;
  font-size: 14px;
  font-weight: 400;
  color: #eee;
  margin-top: 0 !important;
  margin-bottom: 10px
}

.timer.bg-white .time-count {
  max-width: 25%;
  width: 25%;
  margin: 0;
  padding: 10px
}

.timer.bg-white .time-count .text-time {
  font-size: 30px;
  font-weight: 700;
  color: #000;
  text-align: center;
  padding-bottom: 0 !important
}

.timer.bg-white .time-count .text-day {
  text-align: center;
  margin-left: 0;
  font-size: 14px;
  font-weight: 700;
  color: #999;
  margin-top: 0 !important;
  margin-bottom: 10px
}

.timer.bg-grey-time .text-time {
  background-color: #f5f5f5
}

.timer .time-count {
  margin: 8px;
  max-width: 60px;
  text-align: center;
  float: left;
  margin-left: 0
}

.timer .text-time {
  padding: 12px;
  margin: 5px;
  font-weight: 600;
  font-size: 15px;
  background-color: #fff;
  border-radius: 5px
}

.timer .text-day {
  display: block;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  margin-left: 6px
}

.form-group.icon-input {
  position: relative
}

.form-group.icon-input i {
  position: absolute;
  left: 15px;
  top: 19px
}

.form-group.icon-input input {
  padding-left: 35px
}

.form-group.icon-right-input {
  position: relative
}

.form-group.icon-right-input i {
  position: absolute;
  right: 15px;
  top: 12px
}

.form-group.icon-right-input input {
  padding-left: 35px
}

.form-group .style1-input {
  height: 55px;
  line-height: 55px;
  display: block;
  font-size: 12px
}

.form-group .style1-select {
  line-height: 55px;
  height: 55px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #adb5bd !important
}

.form-group .style2-select {
  line-height: 60px;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  color: #adb5bd !important;
  border: 2px #eee solid;
  border-radius: 7px
}

.form-group .style2-input {
  height: 60px;
  line-height: 60px;
  display: block;
  border: 2px #eee solid;
  border-radius: 7px
}

.form-group .style2-input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px
}

.form-group .style2-input::-moz-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px
}

.form-group .style2-input:-ms-input-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px
}

.form-group .style2-input:-moz-placeholder {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px
}

.form-group .style2-input i {
  position: relative;
  top: 6px
}

.form-group .style2-textarea {
  line-height: 20px;
  display: block;
  border: 2px #eee solid;
  border-radius: 7px
}

.search-form {
  position: relative
}

.search-form input {
  height: 55px;
  line-height: 55px;
  padding-left: 20px;
  border-radius: 8px;
  font-weight: 500
}

.search-form i {
  position: absolute;
  top: 18px;
  right: 15px;
  color: #999
}

.searchbox-close {
  float: right
}

.search-form-2 {
  position: relative
}

.search-form-2 input {
  height: 45px;
  line-height: 45px;
  padding-left: 20px;
  border-radius: 8px;
  font-weight: 500
}

.search-form-2 i {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #999
}

.form-control {
  line-height: 50px;
  height: 50px;
  font-size: 14px;
  display: inline-block;
  border: 2px #eee solid
}

.form-control:focus {
  border-color: #555ECE
}

.form-control.style2 {
  height: 60px;
  line-height: 60px
}

.input-file {
  width: .1px;
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1
}

.input-file+.js-labelFile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  vertical-align: middle;
  padding: 10px
}

.input-file+.js-labelFile.btn-tertiary {
  color: #555;
  padding: 0;
  line-height: 40px;
  width: 300px;
  margin: auto;
  display: block;
  border: 2px solid #555
}

.input-file+.js-labelFile.btn-tertiary:focus, .input-file+.js-labelFile.btn-tertiary:hover {
  color: #888;
  border-color: #888
}

.input-file+.js-labelFile i {
  font-size: 24px;
  position: relative;
  top: 5px;
  display: inline-block
}

.input-file+.js-labelFile i.large-icon {
  font-size: 42px
}

.input-file+.js-labelFile.has-file i {
  display: none
}



.quantity {
  height: 60px;
  float: left;
  width: 100px;
  position: relative;
  border: 1px solid #dedede
}

.quantity-input {
  border: 0;
  background: 0 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 18px;
  padding-left: 17px;
  line-height: 60px;
  color: inherit;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none
}

.quantity .qtybutton {
  background-color: transparent;
  z-index: 1;
  position: absolute;
  height: 58px;
  cursor: pointer;
  width: 2rem;
  font-size: 18px;
  font-weight: 300;
  line-height: 60px;
  top: 0;
  text-align: center;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -ms-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  transition: all .3s ease-in
}

.quantity .qtybutton.inc {
  right: 0
}

.quantity .qtybutton.dec {
  left: 0
}

.custom-switch .custom-control-input:checked~.custom-control-label:after {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .15), 0 2px 1px 0 rgba(0, 0, 0, .05)
}

.custom-switch .custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: var(---theme-color);
  border: 0
}

.custom-switch .custom-control-input:active~.custom-control-label::before {
  border: 0
}

.custom-control-input:checked~.custom-control-label.bg-success::before {
  background-color: #10d876 !important;
  border-color: #10d876 !important
}

.bar-container {
  position: relative;
  background-color: #eee;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 5px;
  margin-top: 7px;
  width: 100%;
  float: left
}

.bar-percentage {
  background-color: #aaa;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  height: 5px;
  padding: 0;
  width: 0;
  transition: all .4s ease
}

select.sort {
  border: 0;
  color: #999;
  width: 180px;
  font-size: 13px;
  font-weight: 600;
  padding: 7px 15px;
  border: 2px solid #ddd;
  border-radius: 20px
}

.noUi-target, .noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

.noUi-target {
  position: relative;
  direction: ltr
}

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1
}

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0
}

.noUi-handle {
  position: relative;
  z-index: 1
}

.noUi-stacking .noUi-handle {
  z-index: 10
}

.noUi-state-tap .noUi-origin {
  -webkit-transition: left .3s, top .3s;
  transition: left .3s, top .3s
}

.noUi-state-drag * {
  cursor: inherit !important
}

.noUi-base, .noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.noUi-horizontal {
  height: 3px;
  border-radius: 10px
}

.noUi-horizontal .noUi-handle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: -3px;
  top: -3px;
  background-color: #222
}

.noUi-background {
  background: #ddd
}

.noUi-connect {
  background: #555;
  -webkit-transition: background 450ms;
  transition: background 450ms
}

.noUi-origin, .noUi-target {
  border-radius: 2px
}

.noUi-draggable {
  cursor: w-resize
}

.noUi-vertical .noUi-draggable {
  cursor: n-resize
}

.noUi-handle {
  cursor: default;
  -webkit-box-sizing: content-box !important;
  -moz-box-sizing: content-box !important;
  box-sizing: content-box !important
}

.noUi-handle:active {
  border: 8px solid #345dbb;
  border: 8px solid rgba(53, 93, 187, .38);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  left: -14px;
  top: -14px
}

.custom-radio.size-sm label::before {
  width: 15px !important;
  height: 15px !important;
  top: 5px;
  left: -30px
}

.quantity {
  height: 60px;
  float: left;
  width: 100px;
  position: relative;
  border: 1px solid #dedede
}

.quantity-input {
  border: 0;
  background: 0 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-size: 18px;
  padding-left: 17px;
  line-height: 60px;
  color: inherit;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none
}

.quantity .qtybutton {
  background-color: transparent;
  z-index: 1;
  position: absolute;
  height: 58px;
  cursor: pointer;
  width: 2rem;
  font-size: 18px;
  font-weight: 300;
  line-height: 60px;
  top: 0;
  text-align: center;
  -webkit-transition: all .3s ease-in;
  -moz-transition: all .3s ease-in;
  -ms-transition: all .3s ease-in;
  -o-transition: all .3s ease-in;
  transition: all .3s ease-in
}

.quantity .qtybutton.inc {
  right: 0
}

.quantity .qtybutton.dec {
  left: 0
}

.cart-wrapper .quantity {
  height: 45px
}

.cart-wrapper .quantity-input {
  line-height: 45px;
  font-size: 15px
}

.cart-wrapper .quantity .qtybutton {
  height: 43px;
  line-height: 43px
}

.chart-container .percent {
  position: absolute;
  top: 22%;
  width: 100%;
  text-align: center
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 2.35rem
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.85rem;
  height: 1.675rem;
  opacity: 0
}

.custom-control-label::before {
  position: absolute;
  top: -.175rem;
  display: block;
  width: 1.85rem;
  height: 1.85rem;
  pointer-events: none;
  content: "";
  background-color: #ddd;
  border: #fff solid 1px;
  width: 15px !important;
  height: 15px !important;
  top: 5px;
  left: 0;
  border-radius: 50%;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.custom-control-label::after {
  position: absolute;
  top: -.175rem;
  left: -2.35rem;
  display: block;
  width: 1.85rem;
  height: 1.85rem;
  content: "";
  background: no-repeat 50%/50% 50%
}

.emoji-wrap {
  position: absolute;
  bottom: 70px;
  width: 80%;
  box-shadow: 0 8px 30px rgba(0, 0, 0, .2);
  width: auto;
  background-color: #fff;
  padding: 12px 20px 14px 24px;
  border-radius: 50px;
  display: none
}

@media (max-width:991.98px) {
  .emoji-wrap {
    padding: 4px 10px 13px
  }
}

.emoji-wrap.active {
  display: block
}

.emoji-wrap li {
  margin-right: 5px !important
}

.emoji-wrap li i {
  margin-right: 0 !important;
  font-size: 22px
}

@media (max-width:991.98px) {
  .emoji-wrap li i {
    font-size: 20px
  }
}

.video-js {
  padding-top: 56.25% !important
}

.vjs-poster {
  background-size: cover !important
}

.video-js .vjs-big-play-button {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  width: 70px !important;
  height: 70px !important;
  border-radius: 70px !important;
  line-height: 70px !important;
  border: 0 !important;
  margin-left: -35px;
  margin-top: -35px
}

.notification-box li a {
  padding-left: 70px !important;
  position: relative
}

.notification-box li a .h6, .notification-box li a h6 {
  float: left
}

.notification-box li a .btn-round-md, .notification-box li a img {
  position: absolute;
  left: 15px;
  top: 15px
}

.notification-box li a .notification-react {
  position: absolute;
  top: 40px;
  left: 45px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  line-height: 20px;
  text-align: center
}

.contact-wrap {
  z-index: 1;
  top: -100px;
  padding: 60px 80px 70px 80px
}

@media (max-width:991.98px) {
  .contact-wrap {
    padding: 50px
  }
}

@media (max-width:767.98px) {
  .contact-wrap {
    padding: 40px 30px
  }

  .contact-wrap .display3-size {
    font-size: 40px !important;
    margin-bottom: 30px !important
  }
}

.contact-wrapper {
  padding: 80px;
  max-width: 550px
}

@media (max-width:991.98px) {
  .contact-wrapper {
    padding: 50px;
    max-width: 500px;
    margin-top: 80px;
    margin-bottom: 50px
  }
}

.video-btn {
  display: block;
  position: relative;
  width: 100%;
  float: left
}


.video-btn:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin-left: -35px;
  margin-top: -35px;
  display: block;
  width: 70px;
  height: 70px;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, .1);
  border-radius: 50px
}

.video-bttn {
  position: relative
}


.video-bttn.default {
  background-color: rgba(255, 255, 255, .2);
  border-radius: 50% !important;
  animation: videobtn 1.9s linear infinite;
  display: inline-block;
  width: 60px;
  height: 60px
}

.video-bttn.default:after {
  margin-left: 0;
  position: relative;
  left: 0
}

.question-div {
  transition: all .4s ease
}

.question-div .question-ans {
  cursor: pointer;
  transition: all .4s ease
}

.question-div .question-ans.style2 {
  border: 2px solid transparent !important
}

.question-div .question-ans.style1.active {
  background-color: #555ECE !important;
  color: #fff !important
}

.question-div .question-ans.style2.active {
  border: 2px solid #555ECE !important
}

.question-div .question-ans.style3 span {
  transition: all .4s ease
}

.question-div .question-ans.style3.active span {
  background-color: #555ECE !important;
  color: #fff !important
}

.triangle-after {
  position: relative
}

.triangle-after:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 20px 0 0;
  border-color: #fff transparent transparent transparent;
  content: "";
  position: absolute;
  left: 0;
  bottom: -20px;
  z-index: 2
}

.chat-left, .chat-wrapper, .modal-popup-body {
  height: calc(100vh - 120px)
}

.chat-left::-webkit-scrollbar-track, .chat-wrapper::-webkit-scrollbar-track, .modal-popup-body::-webkit-scrollbar-track {
  background-color: #f1f1f1
}

.chat-left::-webkit-scrollbar, .chat-wrapper::-webkit-scrollbar, .modal-popup-body::-webkit-scrollbar {
  width: 4px;
  background-color: #f1f1f1
}

.chat-left::-webkit-scrollbar-thumb, .chat-wrapper::-webkit-scrollbar-thumb, .modal-popup-body::-webkit-scrollbar-thumb {
  background-color: #ddd
}


@media only screen and (max-width:768px) {
  .arrow-right:after {
    display: none
  }
}

.nav-tabs li a {
  transition: all .4s ease
}

.nav-tabs li a.active {
  border-bottom: 2px #333 solid !important;
  color: #000 !important
}

.login-card {
  min-width: 380px;
  max-width: 400px
}

.coming-soon-card {
  min-width: 500px;
  max-width: 500px
}

.banner-slider .style-div {
  height: 570px
}

@media (max-width:991.98px) {
  .banner-slider .style-div {
    height: 450px
  }
}

.recent-post {
  padding-left: 20px
}

.recent-post li {
  list-style-type: disc;
  margin-bottom: 5px
}

.recent-post li a {
  font-size: 14px;
  font-weight: 500;
  color: #777
}

.next-article:before {
  display: block !important;
  content: "";
  width: 100%;
  height: 2px;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  background: linear-gradient(to right, #fff 0, #eee 35%, #eee 65%, #fff 100%);
  background: -ms-linear-gradient(left, #fff 0, #eee 35%, #eee 65%, #fff 100%);
  background: -o-linear-gradient(left, #fff 0, #eee 35%, #eee 65%, #fff 100%);
  background: -webkit-linear-gradient(left, #fff 0, #eee 35%, #eee 65%, #fff 100%);
  background: -moz-linear-gradient(left, #fff 0, #eee 35%, #eee 65%, #fff 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #fff), color-stop(35%, #eee), color-stop(65%, #eee), color-stop(100%, #fff))
}

.next-article:after {
  display: block !important;
  content: "";
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  background: linear-gradient(to right, #fff 0, #eee 35%, #eee 65%, #fff 100%);
  background: -ms-linear-gradient(left, #fff 0, #eee 35%, #eee 65%, #fff 100%);
  background: -o-linear-gradient(left, #fff 0, #eee 35%, #eee 65%, #fff 100%);
  background: -webkit-linear-gradient(left, #fff 0, #eee 35%, #eee 65%, #fff 100%);
  background: -moz-linear-gradient(left, #fff 0, #eee 35%, #eee 65%, #fff 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(0, #fff), color-stop(35%, #eee), color-stop(65%, #eee), color-stop(100%, #fff))
}

.modal-popup-chat {
  padding: 20px;
  position: fixed;
  bottom: 0;
  right: 270px;
  z-index: 100;
  display: none
}

@media (max-width:767.98px) {
  .modal-popup-chat {
    right: 0
  }
}

.modal-popup-chat .modal-popup-wrap {
  width: 320px
}

.modal-popup-chat .modal-popup-wrap .modal-popup-header {
  top: 0;
  left: 0
}

.modal-popup-chat .modal-popup-wrap .modal-popup-body {
  padding-top: 80px;
  padding-bottom: 80px
}

.modal-popup-chat .modal-popup-wrap .modal-popup-footer {
  bottom: 0;
  left: 0
}

.modal-popup-chat .message-content {
  color: #594939;
  padding: 10px 20px;
  background-color: #fcf6ee;
  border-radius: 20px 20px 20px 0;
  max-width: 80%;
  display: inline-block;
  text-align: left
}

.modal-popup-chat .message.self .message-content {
  border-radius: 20px 20px 0 20px;
  background-color: #e2efff;
  color: #2a4e7f
}

.dot-typing {
  position: relative;
  left: -9999px;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background-color: var(---theme-color);
  color: #9880ff;
  box-shadow: none;
  animation: dotTyping 1.5s infinite linear;
  top: -4px;
  position: relative
}

.list-group .list-group-item {
  border-bottom: 1px solid #eee;
  position: relative
}

.list-group .list-group-item.p-0:after {
  content: "";
  position: absolute;
  color: #ddd;
  font-family: themify;
  right: -5px;
  top: 0;
  font-size: 11px
}

.list-group .list-group-item.no-icon:after {
  display: none
}

.list-group .list-group-item .badge {
  position: absolute;
  right: 0;
  top: 18px
}

.list-group .list-group-item span {
  font-size: 12px;
  color: #999;
  margin-top: 3px
}

.list-group .list-group-item.style2 {
  padding: 12px
}

.list-group .list-group-item.style2 i {
  width: auto;
  height: auto;
  color: #555;
  line-height: 24px
}

.memberlist {
  margin-left: -15px;
  position: relative
}

.memberlist li {
  display: inline-block;
  float: none;
  position: relative;
  width: 15px
}

.memberlist li.last-member a {
  width: 30px;
  height: 30px;
  display: inline-block;
  border-radius: 30px;
  line-height: 30px
}

.chatlist .item {
  width: 55px
}

.chatlist .item img {
  width: 100%;
  border: 2px #555ECE solid;
  border-radius: 50px;
  padding: 2px
}

.chatlist .item.dashed img {
  border: 2px #555ECE dashed
}

.chatlist .item.online:after {
  width: 10px;
  height: 10px;
  background-color: #10d876;
  position: absolute;
  bottom: 3px;
  right: 3px;
  display: inline-block;
  z-index: 2;
  content: "";
  border-radius: 5px
}

.chatlist.style2 .item {
  width: 55px;
  border: 2px solid transparent
}

.chatlist.style2 .item.no-ouline {
  border: 2px #999 solid;
  border-radius: 30px;
  text-align: center;
  line-height: 52px
}

.chatlist.style2 .item.no-ouline:before {
  content: none
}

.chatlist.style2 .item.no-ouline span {
  line-height: 20px;
  width: 110%;
  left: -6%;
  margin-top: 2px
}

.chatlist.style2 .item:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: 0;
  border-radius: inherit;
  background: linear-gradient(to right, red, orange);
  border-radius: 30px !important
}

.chatlist.style2 .item img {
  border: 2px #fff solid;
  margin: 0;
  border-radius: 32px !important
}

.chatlist.style2 .item.online:after {
  bottom: 2px;
  right: 2px
}

.chatlist.style2 .item span {
  position: absolute;
  width: 100%;
  margin-top: 3px;
  text-align: center
}

.chat-bottom {
  background-color: #fff;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 11
}

.chat-form {
  padding: 4px;
  width: 100%
}

.chat-form .form-group {
  width: calc(100% - 90px);
  border-radius: 30px;
  float: left;
  margin: 0 5px;
  position: relative
}

.chat-form .form-group:after {
  background-color: #555ECE;
  opacity: .08;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px
}

.chat-form input {
  width: 100%;
  border: 0;
  border-radius: 30px;
  font-size: 14px;
  padding: 0 15px;
  line-height: 40px;
  color: #ddd;
  font-weight: 500
}

.chat-form button {
  border: 0;
  width: 40px;
  height: 40px;
  border-radius: 20px
}

.chat-form button i {
  font-size: 16px
}

.chat-body {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 0 0
}

@media (max-width:767.98px) {
  .chat-body {
    margin-top: 20px;
    margin-bottom: 0;
    padding-right: 20px;
    padding-left: 20px;
    overflow: auto !important
  }
}

.chat-body .messages-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -moz-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding-bottom: 70px !important
}

.chat-body .messages-content .message-item.outgoing-message {
  margin-left: auto;
  margin-right: 5px
}

.chat-body .messages-content .message-item.outgoing-message .message-user {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end
}

.chat-body .messages-content .message-item.outgoing-message .message-wrap {
  background-color: #555ECE;
  color: #fff
}

.chat-body .messages-content .message-item {
  max-width: 97%;
  margin-bottom: 20px
}

@media (max-width:1199.98px) {
  .chat-body .messages-content .message-item {
    max-width: 75%
  }
}

.chat-body .messages-content .message-item .message-wrap {
  padding: 14px;
  background-color: #fff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 8px 30px rgba(0, 0, 0, .05);
  font-size: 13px;
  color: #000;
  font-weight: 500;
  line-height: 24px;
  position: relative;
  z-index: 2
}

.chat-body .messages-content .message-item .message-wrap:after {
  background-color: #555ECE;
  opacity: .1;
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: 1
}

.chat-body .messages-content .message-item .message-wrap.outgoing-message::after {
  display: none
}

@media (max-width:1199.98px) {
  .chat-body .messages-content .message-item .message-wrap {
    padding: 20px
  }
}

.chat-body .messages-content .message-item .message-user {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 5px
}

.chat-body .messages-content .message-item .message-user .avatar {
  top: 3px;
  position: relative
}

.chat-body .messages-content .message-item .message-user .avatar img {
  display: inline-block;
  margin-bottom: 0;
  height: 40px;
  width: 40px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, .05)
}

.chat-body .messages-content .message-item .message-user .h5, .chat-body .messages-content .message-item .message-user h5 {
  font-size: 14px;
  color: #0c0c0d;
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 0
}

.chat-body .messages-content .message-item .message-user .time {
  font-size: 11px;
  font-weight: 500;
  color: #aaa;
  margin-bottom: 0;
  position: relative;
  top: -3px;
  right: auto;
  display: inline-block
}

.chat-body .messages-content .message-item .message-user .time .ti-double-check:before {
  content: "";
  font-family: themify;
  position: relative;
  left: 9px
}

.chat-body .messages-content .message-item .message-user .time .ti-double-check:after {
  content: "";
  font-family: themify
}

.right-comment {
  position: fixed;
  top: 0;
  width: 320px;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;
  right: 0;
  padding: 0 0 0 8px
}

.cart-wrapper .table tbody td {
  border-top: 0;
  vertical-align: middle;
  padding-top: 10px
}

.input-code {
  width: 200px;
  height: 60px;
  line-height: 60px
}

@media (max-width:767.98px) {
  .input-code {
    width: 165px
  }
}

.img-count {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .6);
  border-radius: 5px;
  text-align: center
}

.img-count b {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-left: -15px;
  margin-top: -15px;
  font-weight: 600 !important
}

.hover-card .card-image {
  overflow: hidden
}

.hover-card .card-image img {
  transition: all .4s ease !important;
  position: relative
}

.hover-card:hover .card-image img {
  transform: scale(1.1)
}

.animation-spin {
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg)
  }
}

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%)
  }
}

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%)
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp
}

@-webkit-keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes slideInUp {
  0% {
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    visibility: visible
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp
}

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    transform: translateY(100%)
  }
}

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%)
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown
}

@-webkit-keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0)
  }
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    visibility: visible
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0)
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown
}

@keyframes videobtn {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, .1), 0 0 0 10px rgba(255, 255, 255, .2), 0 0 0 30px rgba(255, 255, 255, .2)
  }

  100% {
    box-shadow: 0 0 0 10px rgba(255, 255, 255, .2), 0 0 0 30px rgba(255, 255, 255, .2), 0 0 0 50px rgba(255, 255, 255, 0)
  }
}

@keyframes videobtn_red {
  0% {
    box-shadow: 0 0 0 0 rgba(236, 57, 139, .1), 0 0 0 10px rgba(236, 57, 139, .2), 0 0 0 50px rgba(236, 57, 139, .3)
  }

  100% {
    box-shadow: 0 0 0 10px rgba(236, 57, 139, .2), 0 0 0 50px rgba(236, 57, 139, .2), 0 0 0 100px rgba(236, 57, 139, 0)
  }
}

@keyframes dotTyping {
  0% {
    box-shadow: 9984px 0 0 0 #555ECE, 9995px 0 0 0 #555ECE, 10005px 0 0 0 #555ECE
  }

  16.667% {
    box-shadow: 9984px -10px 0 0 #555ECE, 9995px 0 0 0 #555ECE, 10005px 0 0 0 #555ECE
  }

  33.333% {
    box-shadow: 9984px 0 0 0 #555ECE, 9995px 0 0 0 #555ECE, 10005px 0 0 0 #555ECE
  }

  50% {
    box-shadow: 9984px 0 0 0 #555ECE, 9995px -10px 0 0 #555ECE, 10005px 0 0 0 #555ECE
  }

  66.667% {
    box-shadow: 9984px 0 0 0 #555ECE, 9995px 0 0 0 #555ECE, 10005px 0 0 0 #555ECE
  }

  83.333% {
    box-shadow: 9984px 0 0 0 #555ECE, 9995px 0 0 0 #555ECE, 10005px -10px 0 0 #555ECE
  }

  100% {
    box-shadow: 9984px 0 0 0 #555ECE, 9995px 0 0 0 #555ECE, 10005px 0 0 0 #555ECE
  }
}

.right-scroll-bar {
  margin-left: 0;
  overflow-y: scroll
}

.right-scroll-bar::-webkit-scrollbar-track {
  background-color: transparent
}

.right-scroll-bar::-webkit-scrollbar {
  width: 2px;
  background-color: transparent
}

.right-scroll-bar::-webkit-scrollbar-thumb {
  background-color: #aaa
}

.scroll-bar {
  margin-left: 0;
  overflow-y: scroll
}

.scroll-bar::-webkit-scrollbar-track {
  background-color: transparent
}

.scroll-bar::-webkit-scrollbar {
  width: 2px;
  background-color: transparent
}

.scroll-bar::-webkit-scrollbar-thumb {
  background-color: #aaa
}

@media (min-width:991px) {
  .navbar-collapse {
    display: flex !important;
    flex-basis: auto
  }

  .main-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden
  }

  .middle-wrap {
    max-width: 960px;
    margin-left: auto;
    margin-right: auto
  }

  .pr-md--2 {
    padding-right: .5rem !important
  }

  .pl-md--2 {
    padding-left: .5rem !important
  }

  .pt-lg--5 {
    padding-top: 30px !important
  }

  .pb-lg--5 {
    padding-bottom: 30px !important
  }

  .pt-lg--7 {
    padding-top: 75px !important
  }

  .pb-lg--7 {
    padding-bottom: 75px !important
  }

  .pt-lg--10 {
    padding-top: 125px !important
  }

  .pb-lg--10 {
    padding-bottom: 125px !important
  }

  .pl-md--0 {
    padding-left: 0
  }

  .p-md--5 {
    padding: 3rem !important
  }

  .float-right-md {
    float: right !important
  }

  .vh-lg--100 {
    height: 100vh
  }
}

@media (min-width:768px) {
  .p-md--5 {
    padding: 3rem !important
  }

  .vh-md-100 {
    height: 100vh
  }

  .position-absolute-md {
    position: absolute
  }
}

@media (min-width:1800px) {
  .col-xxl-9 {
    flex: 0 0 70%;
    max-width: 70%
  }

  .col-xxl-3 {
    flex: 0 0 30%;
    max-width: 30%
  }

  .col-xxxl-3 {
    flex: 0 0 25%;
    max-width: 25%
  }
}

@media (min-width:1600px) {
  .container-half-fluid {
    width: 1400px
  }

  .p-xxl-5 {
    padding: 3rem !important
  }
}

@media (min-width:1400px) {
  .middle-sidebar-left {
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 960px
  }
}

@media (min-width:1200px) {
  .middle-sidebar-left {
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
    max-width: 960px
  }

  .vh-lg-100 {
    height: 100vh
  }

  .pl-lg--5 {
    padding-left: 3rem !important
  }

  .pr-lg--5 {
    padding-right: 3rem !important
  }

  .p-lg--5 {
    padding: 3rem !important
  }

  .w-lg-75 {
    width: 75%
  }
}

@media (max-width:1600px) {
  .display5-size {
    font-size: 60px !important
  }

  .main-content .middle-sidebar-bottom .sidebar-right {
    visibility: visible;
    opacity: 1
  }

  .main-content .middle-sidebar-bottom .middle-sidebar-left {
    width: 100%
  }

  .right-chat {
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    background-color: rgba(255, 255, 255, .3)
  }

  .main-content {
    padding-right: 0
  }
}

@media (max-width:1400px) {
  .overflow-visible-xl, .overflow-visible-xl .owl-stage-outer {
    overflow: visible !important
  }
}

@media (max-width:1200px) {
  .coming-soon-card, .login-card {
    padding-top: 100px;
    padding-bottom: 100px
  }

  .display4-lg-size {
    font-size: 50px !important
  }
}

@media (max-width:991px) {
  .display2-md-size {
    font-size: 30px !important
  }

  .display4-md-size {
    font-size: 40px !important
  }

  .coming-soon-card, .login-card {
    padding-top: 50px;
    padding-bottom: 50px
  }

  .md-mb-2 {
    margin-bottom: 1rem !important
  }
}

@media (max-width:768px) {
  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .col-xs-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xs-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-xs-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-xs-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .sm-mb-4 {
    margin-bottom: 2rem !important
  }

  .sm-mt-7 {
    margin-top: 5rem !important
  }

  .sm-mb-5 {
    margin-bottom: 3rem !important
  }

  .font-md-xxl {
    font-size: 28px !important
  }

  .sm-text-center {
    text-align: center
  }

  .coming-soon-card, .login-card {
    min-width: 320px
  }

  .product-info-tab li {
    margin-right: 20px !important
  }

  .owl-carousel.category-card, .owl-carousel.category-card .owl-stage-outer {
    overflow: visible !important
  }
}

@media (max-width:576px) {
  .d-none-xs {
    display: none !important
  }

  .xs-mb-4 {
    margin-bottom: 2rem !important
  }

  .xs-mb-2 {
    margin-bottom: 1rem !important
  }

  .xs-p-4 {
    padding: 2rem
  }

  .xs-pt-10 {
    padding-top: 100px
  }

  .owl-theme.nav-xs-none .owl-nav {
    display: none !important
  }

  .col-xss-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .display4-xs-size {
    font-size: 40px !important
  }

  .font-md-xs {
    font-size: 18px !important
  }

  .play-btn {
    width: 25px !important;
    height: 25px !important
  }

  .col-xss-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xss-12 {
    flex: 0 0 100%;
    max-width: 100%
  }

  .col-xss-6 {
    flex: 0 0 50%;
    max-width: 50%
  }

  .col-xss-3 {
    flex: 0 0 25%;
    max-width: 25%
  }

  .col-xss-9 {
    flex: 0 0 75%;
    max-width: 75%
  }

  .col-xss-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
  }

  .clearfix-xs {
    width: 100%;
    height: 1px;
    display: block
  }

  .emoji-wrap li i {
    font-size: 16px
  }
}


.nav-top a img {
  width: 180px;
  margin-top: 8px;
  margin-bottom: 8px
}

.card-body a i {
  font-size: 22px;
  color: #ebebeb;
}

.btn-new-photo {
  margin-bottom: 20px;
}

.firs-step a img {
  width: 200px;
  margin-bottom: 15px;
}

.relative {
  position: relative !important
}

.avatar p {
  cursor: pointer;
}

.form-group input {
  width: 100% !important;
  padding: 10px;
  line-height: 50px;
  height: 50px;
  font-size: 15px;
  display: inline-block;
  border: 2px #eee solid;
}

.form-group select {
  width: 100% !important;
  padding: 10px;
  line-height: 50px;
  height: 50px;
  font-size: 15px;
  display: inline-block;
  border: 2px #eee solid;
}



.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;

}

.lds-spinner div {
  transform-origin: 26px -3px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0px;
  left: 37px;
  width: 1px;
  height: 3px;
  border-radius: 10%;
  background: #000;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.mad-alert-box-inner p {
  font-family: 'Josefin Sans', sans-serif;
}

/* .lds-ripple-3 {
  background: rgb(255 255 255 / 82%);
} */

.validate-order-box-no-margin {
  margin-bottom: 0px;
  margin-top: 0px;

}